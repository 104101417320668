<template>
    <button
        v-if="!href"
        :type="type"
        :form="form"
        :style="styles"
        :name="name"
        :value="value"
        :disabled="disabled"
        class="def-button-primary"
        :class="{ 'def-button-primary_load': load }"
    >
        <slot />
    </button>
    <a
        v-else
        :href="href"
        :style="styles"
        :target="target"
        class="def-button-primary"
    >
        <slot />
    </a>
</template>

<script>
export default {
    name: 'DefButtonPrimary',
    selector: '.def-button-primary',
    props: {
        type: {
            type: String,
            default: 'button',
        },
        href: {
            type: String,
        },
        form: String,
        styles: [String, Object],
        name: String,
        value: String,
        disabled: Boolean,
        load: Boolean,
        target: {
            type: String,
            default: '_self'
        }
    },
}
</script>

<style lang="scss" scoped>
.def-button-primary{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-color: var(--background-green);
    color: #fff;
    font-weight: 600;
    font-size: rem(12);
    line-height: 100%;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    transition: .2s linear background-color;
    padding: 0 15px;
    &:hover{
        opacity: 0.80;
    }
    &:disabled,
    &.disabled {
        cursor: not-allowed;
        opacity: 0.25;
    }
    &_load{
        /* меняем внешний вид курсора */
        cursor: progress;
        /* делаем кнопку полупрозрачной */
        opacity: 0.25;
        pointer-events: none;
    }
}
</style>