<template>
    <div class="checkout-delivery-ukr-post__fields" v-for="field in newPostFields">
        <CheckoutDeliveryNovaPostCity v-if="field.CODE === 'NP_CITY'" :field="field"/>
        <CheckoutDeliveryNovaPostWarehouse v-else-if="field.CODE === 'NP_WAREHOUSE'"/>
        <CheckoutDeliveryNovaPostParcel v-else-if="field.CODE === 'NP_PARCEL'"/>
        <DefInput
                v-else
                :name="field?.inputName"
                :label="field.NAME"
                :required="true"
                :key="field.CODE"
                :placeholder="field.NAME"
                :value="requestData[ field?.inputName ]"
                @change="({target}) => setRequestData({ name: target.name, value: target.value })"
        />
    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';

    export default {
        name: 'CheckoutDeliveryNovaPostFields',
        props: ['result'],
        computed: {
            ...mapState({
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                requestData: ({checkout}) => checkout.data.vue?.requestData,
            }),
            newPostFields() {
                for (let key in this.result.ITEMS) {
                    let delivery = this.result.ITEMS[key];
                    if (delivery.CHECKED) {
                        switch (delivery.CODE) {
                            case 'parcel':
                                return this.novaPostParcelFields;
                            case 'warehouse':
                                return this.novaPostWarehouseFields;
                            case 'address':
                                return this.novaPostAddressFields;
                        }
                    }
                }
            },
            novaPostWarehouseFields() {
                return Object.values(this.deliveryProps)?.filter(el =>['NP_CITY', 'NP_WAREHOUSE'].includes(el.CODE));
            },
            novaPostParcelFields() {
                return Object.values(this.deliveryProps)?.filter(el => ['NP_CITY', 'NP_PARCEL'].includes(el.CODE));
            },
            novaPostAddressFields() {
                return Object.values(this.deliveryProps)?.filter(el => ['NP_CITY', 'STREET', 'HOUSE', 'FLAT'].includes(el.CODE));
            },
        },
        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData',
            }),
        },
    }
</script>

<style lang="scss" scoped>
    .checkout-delivery-ukr-post__fields {
        :deep(.def-input) {
            width: 100%;
            height: 46px;
        }
        /*:deep(.def-select-search) {*/
        /*    margin-bottom: 10px;*/
        /*}*/

    }
</style>