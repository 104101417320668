<template>
    <div class="checkout-delivery-ukr-post__fields" v-for="field in ukrPostFields">
        <CheckoutDeliveryUkrPostCity v-if="field.CODE === 'UP_CITY'" :field="field"/>
        <CheckoutDeliveryUkrPostWarehouse v-else-if="field.CODE === 'UP_WAREHOUSE'"/>
        <CheckoutDeliveryUkrPostStreet v-else-if="field.CODE === 'STREET'"/>
        <DefInput
                v-else
                :name="field?.inputName"
                :label="field.NAME"
                :required="true"
                :key="field.CODE"
                :placeholder="field.NAME"
                :value="requestData[ field?.inputName ]"
                @change="({target}) => setRequestData({ name: target.name, value: target.value })"
        />
    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';

    export default {
        name: 'CheckoutDeliveryUkrPostFields',
        props: ['result'],
        computed: {
            ...mapState({
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                requestData: ({checkout}) => checkout.data.vue?.requestData,
            }),
            ukrPostDeliveryProps() {
                return Object.values(this.deliveryProps)?.filter(el => el.CODE !== 'PROCESS_PERSONAL_DATA' && el.CODE !== 'dontcall');
            },
            ukrPostWarehouseFields() {
                return this.ukrPostDeliveryProps?.filter(el => el.CODE === 'UP_WAREHOUSE' || el.CODE === 'UP_CITY');
            },
            ukrPostAddressFields() {
                return this.ukrPostDeliveryProps?.filter(el => el.CODE !== 'UP_WAREHOUSE');
            },
            ukrPostFields() {
                for (let key in this.result) {
                    let delivery = this.result[key];
                    if (delivery.CHECKED === 'Y') {
                        return delivery.ID == 20 ? this.ukrPostWarehouseFields : this.ukrPostAddressFields;
                    }
                }
            }
        },
        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData',
            }),
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-delivery-ukr-post__fields {
        :deep(.def-input) {
            width: 100%;
            height: 46px;
        }
        /*:deep(.def-select-search) {*/
        /*    margin-bottom: 10px;*/
        /*}*/

    }
</style>