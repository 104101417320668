class GoogleAnalytics4{
	constructor() {
		window.dataLayer = window.dataLayer || [];
	}

	/** self methods **/
	__clearDataLayer() {
		dataLayer.push({ ecommerce: null });
	}

	__formatItem(item) {
		if (item?.dataLayerProduct) {
			return item.dataLayerProduct;
		}

		const product = {
			item_name: item.name, // Name or ID is required.
			item_id: item.id,
			price: item.price,
			item_brand: item.brand,
			item_category: item.category,
			item_variant: item.variant,
			// item_list_name: "Search Results",
			quantity: +item.quantity || 1
		}

		if (item.position) {
			product.index = item.position;
		}

		if (item.list) {
			product.item_list_id = item.list;
		}

		return product;
	}

	__formatItems(items) {
		return items.map(this.__formatItem);
	}

	/** public methods **/
	viewItem(item) {
		this.__clearDataLayer();

		dataLayer.push({
			event: "view_item",
			ecommerce: {
				items: this.__formatItems([item])
			}
		});
	}

	addToCart(item) {
		this.__clearDataLayer();

		dataLayer.push({
			event: "add_to_cart",
			ecommerce: {
				items: this.__formatItems([item])
			}
		});
	}

	removeFromCart(item) {
		this.__clearDataLayer();

		dataLayer.push({
			event: 'remove_from_cart',
			ecommerce: {
				items: this.__formatItems([item]),
			}
		});
	}

	selectItem(item) {
		this.__clearDataLayer();

		dataLayer.push({
			event: "select_item",
			ecommerce: {
				items: this.__formatItems([item])
			}
		});
	}

	beginCheckout(data) {
		this.__clearDataLayer();
		dataLayer.push(data);
	}

	purchase(data){
		this.__clearDataLayer();
		dataLayer.push(data);
	}
}

export default new GoogleAnalytics4();