<template>
    <div class="def-select-search">
        <input
            type="text"
            :name="name"
            :value="value"
            :required="required"
            @invalid.prevent="isInvalid = true"
            class="def-select-search__input"
        >
        <div
            class="def-select-search__front"
            :class="{'def-select-search__front_error': isInvalid}"
            @click="handlerToggle"
        >
            <span
                v-if="$slots.placeholder"
                class="def-select-search__placeholder"
                :class="{ 'def-select-search__center': !isHeader }">
                <slot  name="placeholder" />
            </span>
            <span v-if="isHeader" class="def-select-search__header">
                <slot  name="header" />
            </span>
            <svg
                :class="{ 'def-select-search__close_active': active }"
                class="def-select-search__close"
            >
                <use xlink:href="#svg-select"></use>
            </svg>
        </div>
        <SlideUpDown v-model="active" class="def-select-search__drop">
            <PerfectScrollbar class="def-select-search__scroll" @wheel.prevent ref="city">
                <span v-if="$slots.search" class="def-select-search__search">
                    <slot name="search" />
                    <svg class="def-select-search__magnifying"><use xlink:href="#svg-magnifying"></use></svg>
                </span>
                <div class="def-select-search__items" @click="active = false; isInvalid= false">
                    <slot />
                </div>
            </PerfectScrollbar>
        </SlideUpDown>
    </div>
</template>

<script>
export default {
    name: "DefSelectSearch",
    props: {
        name:{
            type: String,
        },
        value:{
            type: String,
        },
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
        },
    },
    data: () => ({
        active: false,
        isInvalid: false,
    }),
    computed: {
        isHeader() {
            return !!this.$slots?.header()?.find(el => el?.text?.trim() || el?.children);
        },
    },
    watch: {
        active(prevState) {

            if (prevState) {

                document.addEventListener('click', this.closeSelect);
            } else {
                document.removeEventListener('click', this.closeSelect);
            }
        },
        value(newData) {
            if (newData) this.isInvalid = false;
        }
    },
    methods: {
        closeSelect({ target }) {
            if (target.closest('.def-select-search') !== this.$el) {
                this.active = false;
            } else {

                const field = this.$el.querySelector('.autofocus');
                if (field) field.focus()
            }
        },
        handlerToggle() {
            this.active = !this.active;
        }
    },
}
</script>

<style scoped lang="scss">
.def-select-search{
    width: 100%;
    position: relative;
    font-size: rem(14);
    @include point-md{
        font-size: rem(16);
    }
    &__front{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: #FFFFFF;
        min-height: 48px;
        position: relative;
        height: 100%;
        /*height: 48px;*/
        border: 1px solid #c8c7c7;
        border-radius: 3px;
        transition: .2s linear border-bottom-color;
        padding-bottom: 10px;
        padding-right: 18px;
        cursor: pointer;
        &_error{
            border-bottom-color: var(--background-red);
        }
    }
    &__placeholder{
        margin-bottom: 2px;
        //position: absolute;
        //top: 0;
        transition: .2s linear top, .2s linear font-size;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        max-width: calc(100% - 42px);
        text-overflow: ellipsis;
        font-weight: 500;
        /*font-size: rem(10);*/
        font-size: rem(12);

        color: #5F5F67;
    }
    &__center{
        //top: 50%;
        //transform: translateY(-30%);
        /*font-size: rem(16);*/
        font-size: rem(12);
    }
    &__drop{
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 1px);
        background-color: #fff;
        z-index: 2;
    }
    &__scroll{
        max-height: 250px;
        overflow-y: auto;
        border: 1px solid #CACECE;
    }
    &__items{
        :deep( > *){
            display: flex;
            align-items: center;
            padding: 8px 12px;
            cursor: pointer;
            transition: .2s linear background-color;
            &:hover{
                background-color: #FAFAFA;
            }
        }
    }
    &__close{
        position: absolute;
        transition: .2s linear transform;
        right: 0;
        width: 16px;
        height: 16px;
        fill: black;
        &_active{
            transform: rotate(180deg);
        }
    }
    &__search{
        display: block;
        position: relative;
        padding-top: 8px;
        :deep(input){
            width: 100%;
            height: 42px;
            border: none;
            border-bottom: 1px solid var(--background-black);
            font-weight: 500;
            font-size: rem(12);
            padding: 0 30px 0 12px;
        }
    }
    &__magnifying{
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
    }
    &__input{
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
}
</style>