<template>
    <div class="checkout-confirm-button">
        <DefButtonPrimary
                :type="'submit'"
        >
            {{ 'Оформити замовлення' }}
        </DefButtonPrimary>
    </div>
</template>

<script>
    export default {
        name: "CheckoutConfirmButton"
    }
</script>

<style lang="scss" scoped>
    .checkout-confirm-button {
        text-align: center;

        :deep(.def-button-primary) {
            width: 100%;
            margin-bottom: 16px;
            @include point-md {
                width: auto;
                align-self: flex-end;
                min-width: 320px;
            }
            @include point-lg {
                width: 100%;
                min-width: auto;
            }
        }
    }
</style>