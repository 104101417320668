<template>
    <DefSelectSearch
            :required="true"
            :value="requestData[ getParcelInputName ]"
    >
        <template #header>
            {{ requestData[ getParcelInputName ] }}
        </template>
        <template #placeholder>
            {{ 'Поштомат' }}
        </template>
        <template #search>
            <input
                    type="text"
                    class="autofocus"
                    @input="({target}) => handlerInput(target.value)"
                    :placeholder="'Виберіть Поштомат Нової Пошти'"
            >
        </template>
        <div
                v-for="warehouse in warehouses"
                @click="changeWarehouse({warehouse, key: 'NP', warehouseType: 'parcel'})"
        >
            {{ warehouse.printName }}
        </div>
    </DefSelectSearch>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    export default {
        name: "CheckoutDeliveryNovaPostParcel",
        props: ['field'],
        computed: {
            ...mapState({
                warehouses: ({checkout}) => checkout.data?.NovaPostWarehouses,
                NovaPostCity: ({checkout}) => checkout.data?.NovaPostCity,
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                requestData: ({checkout}) => checkout.data.vue?.requestData,
            }),
            getParcelInputName() {
                return Object.values(this.deliveryProps)?.find(el => el.CODE === 'NP_PARCEL')?.inputName;
            },
        },
        methods: {
            ...mapActions({
                setAjax: 'checkout/setAjax',
                getWarehouses: 'checkout/getWarehouses',
                changeWarehouse: 'checkout/changeWarehouse',
            }),
            updateWarehouses(value) {
                this.getWarehouses({
                    cityId: this.NovaPostCity?.UF_XML_ID,
                    warehouse: value,
                    warehouseType: 'parcel',
                    key: 'NovaPost'
                });
            },
            handlerInput(value) {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => this.updateWarehouses(value), 300);
            },
        }

    }
</script>

<style scoped>

</style>