<template>
    <div class="checkout-checkboxes">
        <DefCheckboxCheckout
                @change="({ target }) => setCallMeData(target)"
                :value="this.checked ? 'Y' : 'N'"
                :checked="requestData[callMeProp.inputName] === 'Y'"
                :name="callMeProp.inputName"

        >
            {{ 'Мені можна не телефонувати для підтвердження замовлення.' }}
        </DefCheckboxCheckout>

        <DefCheckboxCheckout
                @change="({ target }) => setPersonalProcessData(target)"
                :value="this.checkedPersonal ? 'Y' : 'N'"
                :checked="requestData[processPersonalDataProp.inputName] === 'Y'"
                :name="processPersonalDataProp.inputName"
                :required="true"
                :errorText="'Погодьтеся з умовами'"

        >
            {{ 'Я згоден (-на) ' }}
            <a href="/include/licenses_detail.php" target="_blank">обробку персональних данних</a>

        </DefCheckboxCheckout>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
    export default {
        name: "CheckoutCheckboxes",
        data() {
            return {
                checked: false,
                checkedPersonal: true,
            }
        },
        computed: {
            ...mapState({
                requestData: ({checkout}) => checkout.data.vue?.requestData,
                deliveryProps: ({ checkout }) => checkout.data.vue?.delivery?.props,
            }),
            processPersonalDataProp() {
                return Object.values(this.deliveryProps)?.find(el => el.CODE === 'PROCESS_PERSONAL_DATA');
            },
            callMeProp() {
                return Object.values(this.deliveryProps)?.find(el => el.CODE === 'dontcall');
            },
        },
        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData',
            }),
            ...mapActions({
                setAjax: 'checkout/setAjax',

            }),
            setCallMeData(target) {
                this.checked = !this.checked;
                this.setRequestData({name: target.name, value: this.checked ? 'Y' : 'N'});
                this.setAjax();
            },
            setPersonalProcessData(target) {
                this.checkedPersonal = !this.checkedPersonal;
                this.setRequestData({name: target.name, value: this.checkedPersonal ? 'Y' : 'N'});
                this.setAjax();
            },
        },
        mounted () {
            this.checkedPersonal = this.processPersonalDataProp.VALUE === 'Y';
        }
    }
</script>

<style lang="scss" scoped>
.checkout-checkboxes {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column !important; //@todo убрать
    align-items: center;
    position: relative;
    flex-flow: wrap;
    margin-top: 5px;
}
</style>