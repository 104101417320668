const { store } = require('../store/index');
// const router = require('../routs/router').default;
import { createApp } from 'vue';


// import vue3dLoader from "vue-3d-loader";


class Vue{
	constructor() {
		this.components = {};
	}

	/** Добавление комопнента Vue для работы с ним на странице */
	setComponent(vueComponent) {
		if (!vueComponent || !vueComponent.name) {
			console.log(vueComponent);
			// if (Core.debug) {
			// 	console.error('Fail set vue component', vueComponent);
			// }
		} else {
			this.components[ vueComponent.name ] = vueComponent;
			// console.log(vueComponent.name);
			// window.Vue.component(vueComponent.name, vueComponent);
		}
	}

	setDefaultData(data) {
		if (process.env.NODE_ENV === 'development') {
			return data;
		}
		return null;
	}


	reInit() {

	}

	_createPropsFromAttributes = ({ dataset }) => {
		try {
			let params = {  }
			for (let key in dataset) {
				if (key === 'vue') continue;
				params[ key ] = JSON.parse(dataset[ key ]);
			}

			return params;
		} catch (err) {
			console.error(err);
		}
	}

	_setGlobalComponents(app) {
		const components = this.components;

		for (let key in components) {
			app.component(key, components[ key ]);
		}
	}

	init() {
		try {
			const items = document.querySelectorAll('[data-vue]:not(.init)');

			items.forEach(el => {
				const componentName = el.dataset.vue;
				el.classList.add('init');

				if (componentName in this.components) {

					const app = createApp(this.components[ componentName ], this._createPropsFromAttributes(el))



					this._setGlobalComponents(app);

					app.use(store);


					// app.use(router);

					// app.use(require('vue-universal-modal').default, {
					// 	teleportTarget: '#modals'
					// })

					// app.use(require('./plugins/modal').default);

					// app.use(vue3dLoader);

					app.mixin(require('../mixins/screenWidth/screenWidth').default);

					app.component('SlideUpDown', require('vue3-slide-up-down').default);


					app.mount(el);
				} else {
					console.error(`component: ${ componentName } is undefined`);
				}
			})
		} catch (err) {
		    console.error(err);
		}
	}

}

export default Vue;