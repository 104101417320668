<template>
    <div class="checkout-delivery-ukr-post">
        <div>Доставка УкрПоштою</div>
        <DefImg :result="result.picture"/>
        <div
                v-for="item in result.ITEMS"
                :key="item.ID"
                class="checkout-delivery-ukr-post__block"
        >
            <div class="checkout-delivery-ukr-post__header">
                <DefRadio
                        :name="delivery.inputName"
                        :checked="item.CHECKED === 'Y'"
                        @change="changeData(delivery.inputName, item.ID)"
                >
                    {{ item.NAME }}
                </DefRadio>

            </div>
        </div>
        <CheckoutDeliveryUkrPostFields :result="result.ITEMS" />
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    export default {
        name: "CheckoutDeliveryUkrPost",
        props: ['result'],
        computed: {
            ...mapState({
                delivery: ({ checkout }) => checkout.data.vue?.delivery,
            }),
        },
        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData'
            }),
            ...mapActions({
                setAjax: 'checkout/setAjax',
            }),
            changeData(name, value) {
                this.setRequestData({ name, value });
                this.setAjax();
            },
        }
    }
</script>

<style lang="scss" scoped>
.checkout-delivery-ukr-post {
    padding: 20px 24px;
    border: 1px solid var(--border-light-grey);
    :deep(.def-img-wrapper) {
        /*max-width: 130px;*/
        height: 90px;
        padding: 15px 0;
        display: inline-flex;
    }
}
</style>