import anime from 'animejs';

class Helpers{
	getType(payload) {
		return Object.prototype.toString.call(payload).slice(8, -1).toLowerCase();
	}
	vnodeToHtmlString(slot) {
		try {
			const createAttributesString = (attr) => {
				if (!attr) return '';
				let attrString = '';

				for (let key in attr) {
					attrString += `${ key }="${ attr[ key ] }" `;
				}

				return attrString;
			}

			if (!Array.isArray(slot) && slot.children) {
				slot = [ slot ]
			}

			const createDom = (slotLevel) => {
				let domString = '';

				for (let el of slotLevel) {
					if (el.type) {
						domString += `
							<${ el.type } ${ createAttributesString(el?.props) }>
								${ el.children && Array.isArray(el.children) ? createDom(el.children) : el.children }					
							</${ el.type }>`
						;
					}
					// else {
					// 	domString += el.text;
					// }
					// if (typeof el.children === 'string') {
					// 	console.log('123');
					// 	domString += el.text;
					// }
				}

				return domString;
			}

			// return document.createRange().createContextualFragment(createDom(slot));
			return createDom(slot);
		} catch (err) {
		    console.error(err);
		}
	}
	scrollPageTo(scrollTop = 0, duration = 500) {
		const scrollElement =
			window.document.scrollingElement ||
			window.document.body ||
			window.document.documentElement;

		anime({
			targets: scrollElement,
			easing: 'easeInOutQuad',
			scrollTop,
			duration,
		})
	}
	getFirstElementObject(obj) {
		return obj[ Object.keys(obj)[0] ];
	}
	setCookie(name, value, expDays) {
		try {
			if (expDays) {
				let date = new Date();
				date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
				const expires = "expires=" + date.toUTCString();
				document.cookie = `${ name } = ${ value }; ${ expires }; path=/`;
			} else {
				document.cookie = `${ name } = ${ value }; path=/`;
			}

		} catch (err) {
		    console.error(err);
		}
	}
	getCookie(cName) {
		try {
			const name = cName + "=";
			const cDecoded = decodeURIComponent(document.cookie); //to be careful
			const cArr = cDecoded .split('; ');
			let res;
			cArr.forEach(val => {
				if (val.indexOf(name) === 0) res = val.substring(name.length);
			})
			return res;
		} catch (err) {
		    console.error(err);
		}
	}
	isInstagram() {
		const ua = navigator.userAgent || navigator.vendor || window.opera;
		const isInstagram = ua.indexOf('Instagram') > -1;

		return isInstagram;
	}
}

export default Helpers;