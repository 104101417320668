<template>
    <label class="def-input">
        <span
            class="def-input__placeholder"
            :class="{ 'def-input__placeholder_center': !saveValue && !isFocus }"
        >
            {{ placeholder }}
        </span>
        <input
            :type="type"
            :name="name"
            :required="required"
            :value="saveValue"
            :autocomplete="autocomplete"
            :id="id"
            :form="form"
            :readonly="readonly"
            :pattern="pattern"
            :class="{
                'def-input__invalid': !isValid || isError,
                'def-input__success': isSuccess
            }"
            @input="handlerInput"
            @click="$emit('click', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            @invalid.prevent="handlerInvalid"
            @blur="handlerBlur"
            @focus="handlerFocus"
            class="def-input__input"
        >
        <transition name="fade">
            <svg
                v-if="!isValid || isError"
                class="def-input__icon-error"
            ><use xlink:href="#svg-error-icon"></use>
            </svg>
        </transition>
        <transition name="fade">
            <svg
                v-if="isSuccess"
                class="def-input__icon-success"
            >
                <use xlink:href="#svg-circle-check"></use>
            </svg>
        </transition>
        <transition name="fade">
            <span
                v-if="!isValid || isShowError"
                class="def-input__error"
            >
                <slot />
            </span>
        </transition>
    </label>
</template>

<script>
export default {
    name: 'DefInput',
    props: {
        name: String,
        placeholder: String,
        required: Boolean,
        autocomplete: Boolean,
        value: {
            type: String,
            default: '',
        },
        modelValue: {
            type: String,
            default: '',
        },
        id: String,
        form: String,
        type: {
            type: String,
            default: 'text',
        },
        isShowError: {
            type: Boolean,
            default: false,
        },
        isSuccess: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },
        isError: {
            type: Boolean,
            default: false
        },
        pattern: {
            type: [String, null],
            default: null,
        }
    },
    data() {
        return {
            isValid: true,
            isFocus: false,
            saveValue: this.value,
        }
    },
    methods: {
        handlerInput(e) {
            this.saveValue = e.target.value;
            // this.$emit('input', e);
            this.$emit('update:modelValue', this.saveValue);
        },
        handlerInvalid(e) {
            if (this.required) {
                this.isValid = false;
            }

            this.$emit('invalid', e);
        },
        handlerBlur(e) {
            const target = e.target;
            if (target.checkValidity() && this.required) {
                this.isValid = true;
            }
            this.isFocus = false;
            this.$emit('blur', e);
        },
        handlerFocus(e) {
            this.isFocus = true;
            this.$emit('focus', e);
        }
    },
    watch: {
        value(newValue) {
            this.saveValue = newValue;
        },
        modelValue(newValue) {
            this.saveValue = newValue;
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.def-input{
    display: inline-block;
    position: relative;
    height: 56px;
    &__placeholder{
        position: absolute;
        top: 0px;
        font-weight: 500;
        /*font-size: rem(10);*/
        font-size: rem(12);
        color: var(--color-dark-grey);
        transition: .2s linear top;
        &_center{
            /*font-size: rem(16);*/
            font-size: rem(12);
            top: 16px;
        }
    }
    &__input{
        /*font-weight: 500;*/
        font-size: rem(16);
        /*border-bottom: 1px solid var(--background-black);*/
        height: 48px;
        border: 1px solid #c8c7c7;
        border-radius: 3px;
        background: #fafafa;
        transition: .2s linear border-bottom-color;
        padding: 6px 20px 0 10px;
        width: 100%;
    }
    &__error{
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: rem(10);
        line-height: 100%;
        color: var(--color-red);
    }
    &__invalid{
        border-bottom-color: var(--background-red);
    }
    &__success{
        border-bottom-color: var(--background-green);
    }
    &__icon-error{
        position: absolute;
        top: 13px;
        right: 0;
        fill: var(--background-red);
        width: 16px;
        height: 16px;
    }
    &__icon-success{
        position: absolute;
        top: 13px;
        right: 0;
        width: 16px;
        height: 16px;
    }
}
</style>