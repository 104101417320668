<template>
    <div class="checkout-delivery-success">
        <div>
            <b>
                <span>{{ delivery.NAME }}</span>
            </b>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "CheckoutDeliverySuccess",
        computed: {
            ...mapState({
                deliveries: ({checkout}) => checkout.data.vue?.delivery.items,
            }),
            delivery() {
                return Object.values(this.deliveries)?.find(el => el.CHECKED === 'Y');
            }
        },
    }
</script>

<style lang="scss" scoped>
    .checkout-delivery-success {
        padding: 30px 35px 25px 35px;
        @include point-md {
            padding: 30px 35px 25px 85px;
            //max-width: 480px;
        }
    }
</style>