<template>
    <div class="def-dragscroll" @mousedown="mouseDownHandler" @touchstart="touchStartHandler">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DefDragScroll',
    selector: '.def-drag-scroll',
    props: ['result'],
    methods: {
        /**
         * custom drag scroll
         */
        mouseDownHandler(e) {
            const element = this.$el;
            element.style.cursor = 'grabbing';
            element.style.userSelect = 'none';
            this.scrollPosition = {
                // The current scroll
                left: element.scrollLeft,
                top: element.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener('mousemove', this.mouseMoveHandler);
            document.addEventListener('mouseup', this.mouseUpHandler);
        },
        mouseMoveHandler(e) {
            const element = this.$el;
            // How far the mouse has been moved
            const dx = e.clientX - this.scrollPosition.x;
            const dy = e.clientY - this.scrollPosition.y;

            // Scroll the element
            element.scrollTop = this.scrollPosition.top - dy;
            element.scrollLeft = this.scrollPosition.left - dx;

            this.$emit('dragScroll', {
                scrollTop: this.scrollPosition.top - dy,
                scrollLeft: this.scrollPosition.left - dx,
            });
        },
        mouseUpHandler() {
            const element = this.$el;
            element.style.cursor = 'grab';
            element.style.removeProperty('user-select');
            document.removeEventListener('mousemove', this.mouseMoveHandler);
            document.removeEventListener('mouseup', this.mouseUpHandler);
        },
        /** handlers for touch devices **/
        touchStartHandler(e) {
            // console.log(e);
            const element = this.$el;
            element.style.cursor = 'grabbing';
            element.style.userSelect = 'none';

            this.scrollPosition = {
                // The current scroll
                left: element.scrollLeft,
                top: element.scrollTop,
                // Get the current mouse position
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
            };

            document.addEventListener('touchmove', this.touchMoveHandler);
            document.addEventListener('touchend', this.touchEndHandler);
        },
        touchMoveHandler(e) {
            const element = this.$el;
            // How far the mouse has been moved
            const dx = e.touches[0].clientX - this.scrollPosition.x;
            const dy = e.touches[0].clientY - this.scrollPosition.y;

            // Scroll the element
            element.scrollTop = this.scrollPosition.top - dy;
            element.scrollLeft = this.scrollPosition.left - dx;

            this.$emit('dragScroll', {
                scrollTop: this.scrollPosition.top - dy,
                scrollLeft: this.scrollPosition.left - dx,
            });
        },
        touchEndHandler() {
            const element = this.$el;
            element.style.cursor = 'grab';
            element.style.removeProperty('user-select');
            document.removeEventListener('touchmove', this.touchMoveHandler);
            document.removeEventListener('touchend', this.touchEndHandler);
        },
        /**
         * end custom drag scroll
         */
    }
}
</script>

<style lang="scss" scoped>
.def-dragscroll{

}
</style>