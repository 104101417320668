<template>
    <span class="def-price-old">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'DefPriceOld',
    selector: '.def-price-old',
}
</script>

<style lang="scss" scoped>
.def-price-old{
    font-size: rem(9);
    text-align: center;
    text-decoration-line: line-through;
    color: var(--color-dark-grey);
}
</style>