import GoogleAnalytics4 from "./GoogleAnalytics4";

export default class Analytics{
	static viewDetailProduct(product) {
		if (product) {
			GoogleAnalytics4.viewItem({ ...product });
		}
	}

	static addToBasket(product) {
		if (product) {
			GoogleAnalytics4.addToCart({ ...product, quantity: 1 });
		}
	}

	static removeFromBasket(product) {

		if (product) {
			GoogleAnalytics4.removeFromCart({ ...product });
		}
	}

	static clickOnProduct(product) {
		if (product) {
			GoogleAnalytics4.selectItem(product);
		}
	}

	static viewCheckout(data) {
		try {
			if (data?.ecommerce?.items?.length > 0) {
				GoogleAnalytics4.beginCheckout(data);
			}
		} catch (err) {
		    console.error(err);
		}
	}

	static confirmCheckout(data) {
		const orderId = data?.ecommerce?.transaction_id;
		const ga4Key = `ga4PurchaseOrderId${orderId}`;
		if (orderId && !localStorage.getItem(ga4Key)) {
			GoogleAnalytics4.purchase(data);
			localStorage.setItem(ga4Key, true);
		}
	}

	static buyOneClick(items, generalData) {
		const products = Object.values(items);

		if (products) {
			GoogleAnalytics4.purchase(products, generalData);
		}
	}
}