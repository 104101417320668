<template>
    <div class="checkout-delivery">
        <div class="checkout-delivery__titles">
            <div
                    v-for="item in items"
                    :key="item.ID"
                    class="checkout-delivery__block"
                    :class="{ 'checkout-delivery__block_disabled': item.DISABLED }"
            >
                <div class="checkout-delivery__header">
                    <DefRadio
                            :name="item?.inputName || delivery.inputName"
                            :checked="isChecked(item)"
                            @change="changeData(delivery.inputName, item.ID)"
                    >
                        {{ item.NAME }}
                    </DefRadio>
                </div>
            </div>
        </div>

        <div class="checkout-delivery__sub-deliveries">
            <div v-for="item in items">
                <div v-if="isChecked(item)" class="checkout-delivery__sub-delivery">
                    <CheckoutDeliveryNovaPost v-if="item.ID == 18" :result="item"/>
                    <CheckoutDeliveryUkrPost v-else-if="item.ID == 20" :result="item"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';


    export default {
        name: 'CheckoutDelivery',
        computed: {
            ...mapState({
                delivery: ({checkout}) => checkout.data.vue?.delivery,
                items: ({checkout}) => checkout.data.vue?.delivery?.items,
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                inputStoreID: ({checkout}) => checkout?.data?.vue?.delivery?.inputStoreID,
                NovaPostCity: ({checkout}) => checkout.data?.NovaPostCity,
                UkrPostCity: ({checkout}) => checkout.data?.UkrPostCity,
                // requestData: ({ checkout }) => checkout.data.vue?.requestData,
            }),

        },

        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData'
            }),
            ...mapActions({
                setAjax: 'checkout/setAjax',
            }),

            changeData(name, value) {
                this.setRequestData({name, value});
                this.saveDeliveryFields(value);
                this.setAjax();
            },
            isChecked(item) {
                return item.CHECKED === 'Y' && item.ITEMS;
            },

            /**
             * mechanic of saving props after switching on another delivery
             * now - just save a city
             */
            saveDeliveryFields(id) {
                let npCityPrintName = this.NovaPostCity?.printName;
                let upCityPrintName = this.UkrPostCity?.printName;

                switch (id) {
                    case '18':
                        if (npCityPrintName) {
                            /** save city for nova post */
                            this.setRequestData({name: this.delivery.npCityInputName, value: npCityPrintName});
                        }
                        break;
                    case '20':
                        if (upCityPrintName) {
                            /** save city for ukr post */
                            this.setRequestData({name: this.delivery.upCityInputName, value: upCityPrintName});
                        }
                        break;
                }

            }
        },
        mounted() {
            // init current city
            // if (this.userCity) {
            //     this.setRequestData([
            //         { name: this.getCityKey, value: this.userCity.UF_NAME }
            //     ]);
            // }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-delivery {
        display: flex;
        flex-direction: column;
        @include point-md {
            flex-direction: row;
        }
        &__header {
            /*display: flex;*/
            /*justify-content: space-between;*/
            /*align-items: center;*/
            /*padding: 16px 0;*/
        }

        &__footer {
            //padding-left: 34px;
            padding-bottom: 16px;
        }

        &__block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            &_disabled {
                pointer-events: none;
                opacity: .5;
            }
        }

        &__price {
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: var(--color-dark-grey);
            text-align: end;
        }

        &__free {
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            text-align: end;
            color: var(--text-colour-secondary);
        }

        &__sub-deliveries {
            @include point-md {
                margin-left: auto;
            }
        }

        &__sub-delivery {
            @include point-md {
                max-width: 310px;
            }
        }

        :deep(.def-radio-component) {
            align-items: center;
            font-weight: 500;
            font-size: 16px;
        }
    }
</style>