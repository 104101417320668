<template>
    <label class="def-radio">
        <input
            :name="name"
            :value="value"
            :checked="checked"
            type="radio"
            class="def-radio__input"
        >
        <span class="def-radio__checkbox"></span>
        <span class="def-radio__text">
            <slot />
        </span>
    </label>
</template>

<script>
export default {
    name: 'DefRadio',
    selector: '.def-radio',
    props: ['name', 'value', 'checked'],
}
</script>

<style lang="scss" scoped>
.def-radio{
    display: inline-flex;
    cursor: pointer;
    &__input{
        display: none;
        &:checked + .def-radio__checkbox{
            border-color: var(--background-green);
            &:before{
                background-color: var(--background-green);
            }
        }
    }
    &__checkbox{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--border-grey);
        transition: .2s linear border-color;
        flex-shrink: 0;
        &:before{
            content: '';
            width: 12px;
            height: 12px;
            background-color: transparent;
            border-radius: 50%;
            transition: .2s linear background-color;
        }
    }
    &__text{
        margin-left: 12px;
    }
}
</style>