export default {
    state: {
        data: {
            activeStep: 1,
            checkoutBlockResult: {},
            /**
             * @todo place by vuex (ukr post, nova post, etc)
             */
            UkrPostCities: [],
            UkrPostStreets: [],
            UkrPostWarehouses: [],
            UkrPostCity: null,
            UkrPostStreet: null,
            UkrPostWarehouse: null,

            /**
             * nova post fields
             */
            NovaPostCities: [],
            NovaPostWarehouses: [],
            NovaPostParcels: [],
            NovaPostCity: null,
            NovaPostWarehouse: null,
            NovaPostParcel: null,
        }
    },
    mutations: {
        ['checkout/setData'] (store, data) {
            try {
                store.data = {
                    ...data,
                    UkrPostCity: {...store.data.UkrPostCity},
                    UkrPostStreet: {...store.data.UkrPostStreet},
                    UkrPostWarehouse: {...store.data.UkrPostWarehouse},
                    NovaPostCity: {...store.data.NovaPostCity},
                    novaPostParcel: {...store.data.novaPostParcel},
                    NovaPostWarehouse: {...store.data.NovaPostWarehouse},
                    checkoutBlockResult: {...store.data.checkoutBlockResult},
                    activeStep: store.data.activeStep,
                };
            } catch (err) {
                console.error(err);
            }

        },
        ['checkout/setDataByType'] (store, payload) {
            try {
                // console.log(payload.data)
                store.data = {
                    ...store.data,
                    [payload.type]: payload.data

                };
            } catch (err) {
                console.error(err);
            }
        },

        /**
         * Записуем данные в Object для дальнейшей отправки на сервер.
         * @param store
         * @param payload
         */
        ['checkout/setRequestData'] (store, payload) {
            try {
                let data = JSON.parse(JSON.stringify(store.data));

                const setData = (DataLink, payloadLink) => {
                    const { name, value } = payloadLink;

                    if (typeof value !== 'undefined') {
                        DataLink.vue.requestData[ name ] = value;
                    } else {
                        delete DataLink.vue.requestData[ name ];
                    }
                }

                switch (App.helpers.getType(payload)) {
                    case 'object':
                        setData(data, payload);
                        break;
                    case 'array':
                        payload.forEach(el => setData(data, el));
                        break;
                }

                store.data = data;

            } catch (err) {
                console.error(err);
            }
        },
    },
    actions: {
        /**
         * Собираем данные и делаем запрос на сервер при изменении данных
         *
         * @param getters
         * @param commit
         * @param data
         * @param payload
         * @return {Q.Promise<void>}
         */
        async ['checkout/setAjax'] ({ getters, commit, dispatch, state: { data } }, payload) {
            try {
                commit('global/loaderStart');

                const { requestData, settings } = data?.vue;
                const { requestFields } = data.vue.settings;

                // формируем данные для multidimensional array

                let formData = {
                    order: {
                        ...requestData,
                        [settings.actionKey]: settings.actionSave,
                    },
                    [settings.actionKey]: settings.actionRefresh,
                };

                if ( payload ) {
                    formData.order[payload.name] = payload.value;
                }

                for(let key in requestFields) {
                    if (key === 'double') {
                        requestFields[key].forEach(el => formData[el] = formData.order[el]);
                    } else if (key === 'main') {
                        requestFields[key].forEach(el => {
                            formData[el] = formData.order[el];
                            delete formData.order[el];
                        })
                    }
                }

                // отправляем данные на сервер

                const response = await axios.post(settings.ajaxUrl, formData);

                commit('global/loaderStop');

                // console.log(response)
                if (!response.data.error) {
                    commit('checkout/setData', response.data.order);



                } else {
                    throw `request error status ${ response.status }`;
                }
            } catch (err) {
                console.error(err);
            }
        },
        /**
         * Собираем данные и сохраняем заказ после ответа делаем редирект
         *
         * @param getters
         * @param commit
         * @param data
         * @param payload
         * @return {Q.Promise<void>}
         */
        async ['checkout/saveOrder'] ({ state: { data }, dispath }) {
            try {
                const { requestData, settings } = data?.vue;

                let formData = {
                    ...requestData,
                    [settings.actionKey]: settings.actionSave,
                };
                const response = await axios.post(settings.ajaxUrl, formData)

                if (response?.data?.order?.REDIRECT_URL) {
                    location.href = response.data.order.REDIRECT_URL;
                } else {
                    const errorTypes = response?.data?.order?.ERROR;
                    console.log(errorTypes);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async ['checkout/getCities']({ state: { data }, commit }, payload ) {
            try {
                const { city, key } = payload;
                const method = `get${key}Cities`;

                const { path, action, LANGUAGE_ID } = data?.vue?.routes?.[method];

                const requestParams = {
                    action,
                    city,
                    LANGUAGE_ID
                };
                const { status, data: responseData } = await axios.post(path, requestParams);
                if (status !== 200) {
                    throw `set default city bad response status ${status}`
                }

                const entity = `${key}Cities`;
                commit('checkout/setData', {
                    ...data,
                    [entity] : [...responseData.cities]
                });
            } catch (err) {
                console.error(err);
            }
        },
        async ['checkout/getWarehouses']({ state: { data }, commit }, { cityId , warehouse, warehouseType, key } ) {
            try {
                const method = `get${key}Warehouses`;
                const { path, action, LANGUAGE_ID } = data?.vue?.routes?.[method];
                const requestParams = {
                    action,
                    cityId,
                    warehouse,
                    warehouseType,
                    LANGUAGE_ID
                };

                const { status, data: responseData } = await axios.post(path, requestParams);
                if (status !== 200) {
                    throw `set default city bad response status ${status}`
                }

                const entity = `${key}Warehouses`;
                commit('checkout/setData', {
                    ...data,
                    [entity]: [...responseData.warehouses]
                });
            } catch (err) {
                console.error(err);
            }
        },
        async ['checkout/getUkrPostStreets']({ state: { data }, commit }, { cityId , street } ) {
            try {
                const { path, action, LANGUAGE_ID } = data?.vue?.routes?.getUkrPostStreets;
                const requestParams = {
                    action,
                    cityId,
                    street,
                    LANGUAGE_ID
                };

                const { status, data: responseData } = await axios.post(path, requestParams);
                if (status !== 200) {
                    throw `set default city bad response status ${status}`
                }

                console.log(responseData)
                commit('checkout/setData', {
                    ...data,
                    UkrPostStreets: [...responseData.streets]
                });
            } catch (err) {
                console.error(err);
            }
        },
        async ['checkout/changeCity'] ({ state: { data }, commit, dispatch, getters }, payload) {
            try {
                const { city, key } = payload;
                const props = Object.values(data.vue.delivery.props);

                const getWarehousesInputName = props?.find(el => el.CODE === `${key}_WAREHOUSE`)?.inputName;
                const getCityKey = props?.find(el => el.CODE === `${key}_CITY`)?.inputName;

                /** clean active warehouse **/
                if (getWarehousesInputName) {
                    commit('checkout/setRequestData',[
                        { name: getWarehousesInputName },
                    ]);
                }

                switch (key) {
                    case 'UP':
                        commit('checkout/setDataByType', {type: 'UkrPostCity', data: city});
                        break;
                    case 'NP':
                        commit('checkout/setDataByType', {type: 'NovaPostCity', data: city});
                        break;
                }


                commit('checkout/setRequestData', { name: getCityKey, value: city.printName });
                dispatch('checkout/setAjax');
            } catch (err) {
                console.error(err);
            }
        },

        async ['checkout/changeUkrPostStreet'] ({ state: { data }, commit, dispatch, getters }, payload) {
            try {
                const props = Object.values(data.vue.delivery.props);
                const getWarehouseKey = props?.find(el => el.CODE === 'STREET')?.inputName;
                const getWarehousesInputName = props?.find(el => el.CODE === 'UP_WAREHOUSE')?.inputName;

                /** clean active warehouse **/
                if (getWarehousesInputName) {
                    commit('checkout/setRequestData',[
                        { name: getWarehousesInputName },
                    ]);
                }
                commit('checkout/setDataByType', {type: 'UkrPostStreet', data: payload});
                commit('checkout/setRequestData', { name: getWarehouseKey, value: payload.printName });
                dispatch('checkout/setAjax');
            } catch (err) {
                console.error(err);
            }
        },

        /** Nova Post */
        async ['checkout/changeWarehouse'] ({ state: { data }, commit, dispatch, getters }, payload) {
            try {
                const { warehouse, warehouseType, key } = payload;
                const { npWarehouseRefInputName, npCityRefInputName, npAllAddressInputName } = data.vue.delivery;
                const props = Object.values(data.vue.delivery.props);

                let getWarehouseKey = props?.find(el => el.CODE === `${key}_WAREHOUSE`)?.inputName;

                /**
                 * mechanic of clear ukr/nova post address fields after choosing a warehouse
                 * @type {string[]}
                 */
                if (1) {
                    let clearFieldsCodes = ['NP_PARCEL', 'STREET', 'HOUSE', 'FLAT'];

                    /** for nova post (parcel/warehouse) */
                    if (warehouseType === 'parcel') {
                        getWarehouseKey = props?.find(el => el.CODE === `${key}_PARCEL`)?.inputName;
                        delete clearFieldsCodes[0];
                        clearFieldsCodes.push('NP_WAREHOUSE');
                    }

                    const clearFields = props?.filter(el => clearFieldsCodes.includes(el.CODE));
                    let clearRequestData = [];
                    clearFields.forEach((el) => {
                        clearRequestData.push({ name: el.inputName });
                    })
                    /** clean active warehouse **/
                    if (clearRequestData.length > 0) {
                        commit('checkout/setRequestData', clearRequestData);
                    }
                }

                switch (key) {
                    case 'UP':
                        commit('checkout/setDataByType', {type: 'UkrPostWarehouse', data: warehouse});
                        break;
                    case 'NP':

                        commit('checkout/setDataByType', {type: 'NovaPostWarehouse', data: warehouse});
                        commit('checkout/setRequestData', [
                            { name: npWarehouseRefInputName, value: warehouse.UF_XML_ID },
                            { name: npCityRefInputName, value: warehouse.UF_CITY_XML_ID },
                            { name: npAllAddressInputName, value: `Доставка Новой Почтой в Город:${data.NovaPostCity?.printName} Отделение:${warehouse?.printName}` },

                        ]);
                        break;
                }
                commit('checkout/setRequestData', { name: getWarehouseKey, value: warehouse.printName });
                dispatch('checkout/setAjax');
            } catch (err) {
                console.error(err);
            }
        },
    },
    getters: {
        ['checkout/getActiveDelivery']: ({ data }) => {
            const items = data.vue?.delivery?.items;
            if (items) {
                return Object.values(items).find(el => el.CHECKED === 'Y');
            }
        },
    }
}