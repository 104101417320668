/**
 * Подключаем Vue библиотеки
 */
App.vue.setComponent(require('vue3-perfect-scrollbar').PerfectScrollbar); // name PerfectScrollbar

/** checkout */
App.vue.setComponent(require('./components/Checkout/Checkout.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutConfirmButton.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutCheckboxes.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutBlock.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutTotal.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutContact/CheckoutContact.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutContact/CheckoutContactSuccess.vue').default);
App.vue.setComponent(require('./components/GoogleAnalytics/CheckoutPurchase.vue').default);

App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDelivery.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliverySuccess.vue').default);

/** checkout ukr post */
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryUkrPost/CheckoutDeliveryUkrPostFields.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryUkrPost/CheckoutDeliveryUkrPostCity.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryUkrPost/CheckoutDeliveryUkrPostWarehouse.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryUkrPost/CheckoutDeliveryUkrPostStreet.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryUkrPost/CheckoutDeliveryUkrPost.vue').default);

/** checkout nova post */
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryNovaPost/CheckoutDeliveryNovaPost.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryNovaPost/CheckoutDeliveryNovaPostFields.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryNovaPost/CheckoutDeliveryNovaPostCity.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryNovaPost/CheckoutDeliveryNovaPostWarehouse.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutDelivery/CheckoutDeliveryNovaPost/CheckoutDeliveryNovaPostParcel.vue').default);

/** checkout paysystem */
App.vue.setComponent(require('./components/Checkout/CheckoutPaySystem/CheckoutPaySystem.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutPaySystem/CheckoutPaySystemSuccess.vue').default);

// Checkout Basket
App.vue.setComponent(require('./components/Checkout/CheckoutBasket/CheckoutBasket.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutBasket/CheckoutBasketHeader.vue').default);
App.vue.setComponent(require('./components/Checkout/CheckoutBasket/CheckoutBasketProduct.vue').default);

//Def
App.vue.setComponent(require('./components/Def/DefImg/DefImg.vue').default);
App.vue.setComponent(require('./components/Def/DefEmail/DefEmail.vue').default);
App.vue.setComponent(require('./components/Def/DefPhone/DefPhone.vue').default);
App.vue.setComponent(require('./components/Def/DefInput/DefInput.vue').default);
App.vue.setComponent(require('./components/Def/DefTextArea/DefTextArea.vue').default);
App.vue.setComponent(require('./components/Def/DefCheckbox/DefCheckbox.vue').default);
App.vue.setComponent(require('./components/Def/DefCheckboxCheckout/DefCheckboxCheckout.vue').default);
App.vue.setComponent(require('./components/Def/DefRadio/DefRadio.vue').default);
App.vue.setComponent(require('./components/Def/DefSelect/DefSelectSearch.vue').default);
App.vue.setComponent(require('./components/Def/DefSelect/DefSelectWrapper.vue').default);
App.vue.setComponent(require('./components/Def/DefTitle/DefTitle.vue').default);
App.vue.setComponent(require('./components/Def/DefButtonPrimary/DefButtonPrimary.vue').default);
App.vue.setComponent(require('./components/Def/DefButtonSecondary/DefButtonSecondary.vue').default);
App.vue.setComponent(require('./components/Def/DefPrice/DefPrice.vue').default);
App.vue.setComponent(require('./components/Def/DefPriceOld/DefPriceOld.vue').default);
App.vue.setComponent(require('./components/Def/DefSale/DefSale.vue').default);
App.vue.setComponent(require('./components/Def/DefSlider/DefSlider.vue').default);
App.vue.setComponent(require('./components/Def/DefDragScroll/DefDragScroll.vue').default);