<template>
    <div class="checkout-total">
        <DefTitle :result="2">
            <h2>{{ 'Ваше замовлення' }}</h2>
            <a href="/basket/">змінити</a>
        </DefTitle>
        <div class="checkout-total__items">
            <!--price-->
            <div class="checkout-total__row">
                <p class="checkout-total__name">
                    {{ 'Товарів на:' }}
                </p>
<!--                <p class="checkout-total__value">-->
<!--                    {{ price.printPriceOld || price.printPrice }}-->
<!--                </p>-->
                <div class="checkout-total__value">
                    <DefPrice>
                        {{ price.printPriceOld }}
                    </DefPrice>
                    <DefPriceOld>
                        {{ price.printPrice }}
                    </DefPriceOld>
                </div>
            </div>

<!--            Вага-->
            <div class="checkout-total__row">
                <p class="checkout-total__name">
                    {{ 'Загальна вага' }}
                </p>
                <p class="checkout-total__value">
                    {{ printProductsWeight }}
                </p>
            </div>

            <!--discount-->
            <div v-if="price.printDiscount" class="checkout-total__row">
                <p class="checkout-total__name">
                    {{ 'Економія' }}
                </p>
                <div class="checkout-total__value">
                    <DefSale class="checkout-total__value">
                        - {{ price.discountPriceFormated }}
                    </DefSale>
                </div>
            </div>


            <!--delivery-->
<!--            <div class="checkout-total__row">-->
<!--                <p class="checkout-total__name">-->
<!--                    {{ messages.delivery }}-->
<!--                </p>-->
<!--                <p class="checkout-total__value">-->
<!--                    {{ getCheckedDelivery.PRICE_FORMATED }}-->
<!--                </p>-->
<!--            </div>-->
            <!--total-->
            <div class="checkout-total__row">
                <p class="checkout-total__name">
                    {{ 'Разом:' }}
                </p>
                <p class="checkout-total__total">
                    {{ price.printPrice }}
                </p>
            </div>

        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'CheckoutTotal',
    computed: {
        ...mapState({
            price: ({ checkout }) => checkout.data.vue.basketPrice,
            printProductsWeight: ({ checkout }) => checkout.data.vue?.printProductsWeight,
        }),

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.checkout-total{
    display: flex;
    flex-direction: column;
    /*padding: 24px 15px;*/
    /*background-color: #F6F6F6;*/
    border: 1px solid var(--border-light-grey);
    @include point-md{
        /*padding: 32px 24px;*/
    }
    @include point-lg{
        /*padding: 32px 40px;*/
    }
    &__items{
        display: grid;
        grid-row-gap: 15px;
        margin-bottom: 20px;
        /*&:after {*/
        /*    content: " ";*/
        /*    display: block;*/
        /*    clear: bottom;*/
        /*}*/
    }
    &__row{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
        padding: 0 20px 0 20px;
        position: relative;

        &:last-child {
            color: black;
            margin-top: 40px;
            padding: 20px 20px 0 20px;
            border-top: 1px solid var(--border-light-grey);
            .checkout-total__name {
                color: black;
            }
            .checkout-total__total {
                font-size: rem(16);
                font-weight: 700;
            }
        }
        /*Dot Leaders set*/
        &:not(:last-child) {
            &:before {
                content: "";
                position: absolute;
                top: 10px;
                left: 30px;
                right: 30px;
                height: 1px;
                background: url(/local/templates/aspro_max/css/../images/dots.png) 0 0 repeat-x;
                white-space: nowrap;
                overflow: hidden;
            }
        }


    }
    &__name{
        justify-self: flex-start;
        /*font-weight: 600;*/
        font-size: rem(13);
        line-height: 125%;
        color: #5F5F67;
        background: #fff;
        z-index: 1;
        padding: 0 10px 0 0;
    }

    &__value{
        justify-self: flex-end;
        font-weight: 600;
        font-size: rem(14);
        line-height: 125%;
        display: flex;
        flex-direction: column;
        position: relative;
        background: #fff;

        :deep(.def-sale) {
            border: 1px solid #ffd83a;
            border-radius: 3px;
            background: #fff8db;
            padding: 0 5px 0;
            color: unset;
            overflow: hidden;
            /* position: relative; */
            z-index: 1;
        }
        /*Dot Leaders clear*/
        &:before {
            content: "";
            background: #fff;
            display: block;
            width: 13px;
            top: 0;
            bottom: 0;
            left: -13px;
            z-index: 2;
            position: absolute;
        }


    }
    &__total{
        justify-self: flex-end;
        font-weight: 600;
        font-size: rem(20);
        line-height: 100%;
    }
    &__text{
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: #5F5F67;
        @include point-md{
            align-self: flex-end;
            max-width: 254px;
            text-align: right;
        }
        @include point-lg{
            max-width: unset;
            text-align: left;
        }
    }

    :deep(.def-link){
        color: #5F5F67;
    }
    :deep(.def-sale){
        font-size: rem(14);
        line-height: 125%;
    }
    :deep(.def-message-error){
        margin-bottom: 16px;
    }
    :deep(.def-title){
        display: flex;
        border-bottom: 1px solid var(--border-light-grey);
        position: relative;
        padding: 20px;
    }
    :deep(.def-title > *){
        font-family: Montserrat,Arial,sans-serif;
        font-weight: 400;
        letter-spacing: unset;
    }
    :deep(.def-title > h2){
        text-transform: none;
    }
    :deep(.def-title > a){
        font-size: rem(10);
        margin-left: auto;
        min-width: 50px;
        @include point-md{
            position: relative;
            top: 7px;
        }

    }
}

</style>