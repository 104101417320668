<template>
    <div class="checkout-pay-system">
        <div v-for="(item, key) in paySystem.items">
            <div class="checkout-pay-system__header"
                 :class="{ 'checkout-pay-system__header_disabled': item.DISABLED }"
            >
                <DefRadio
                    :name="paySystem.inputName"
                    :checked="item.CHECKED === 'Y'"
                    :key="key"
                    @change="changeData(paySystem.inputName, item.ID)"
                >
                    {{ item.NAME }}
                </DefRadio>
            </div>
<!--            <div class="checkout-pay-system__footer"></div>-->
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    name: 'CheckoutPaySystem',
    computed: {
        ...mapState({
            paySystem: ({ checkout }) => checkout.data.vue?.paySystem,
        }),
    },
    methods: {
        ...mapMutations({
            setRequestData: 'checkout/setRequestData'
        }),
        ...mapActions({
            setAjax: 'checkout/setAjax',
        }),
        changeData(name, value) {
            this.setRequestData({name, value});
            this.setAjax();
        },
    }
}
</script>

<style lang="scss" scoped>
.checkout-pay-system{
    &__header{
        padding: 12px 0;
        &_disabled{
            pointer-events: none;
            opacity: .5;
        }
    }
    &__footer{
        padding-left: 34px;
    }
}
</style>