<template>
    <div class="checkout-basket-product">
        <div class="checkout-basket-product__image">
            <a :href="result.detailPage">
             <span class="checkout-basket-product__title" v-if="isShowTitle && screenWidth >= 992">
                {{ 'Товар' }}
            </span>
                <span class="checkout-basket-product__picture">
                <DefImg :result="result.picture"/>

            </span>
            </a>
        </div>

        <div class="checkout-basket-product__overview">
            <span class="checkout-basket-product__title" v-if="isShowTitle && screenWidth >= 992">
                {{ 'Найменування' }}
            </span>
            <div>
                <a :href="result.detailPage" class="checkout-basket-product__description">
                    <span class="checkout-basket-product__name">
                        {{ result.name }}
                    </span>
                </a>
            </div>
        </div>

        <div class="checkout-basket-product__article">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Артикул' }}
            </span>
            <div>
                <span class="checkout-basket-product__value">{{ result.article }}</span>
            </div>
        </div>

        <div class="checkout-basket-product__brand">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Бренд' }}
            </span>
            <div v-if="result.brand">
                <a :href="result.brand.detailPage">
                    <span class="checkout-basket-brand__name">
                        {{ result.brand.name }}
                    </span>
                </a>
            </div>
        </div>

        <div class="checkout-basket-product__purpose">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Призначення' }}
            </span>
            <div v-for="purposeItem in result?.purpose">
                <div>
                    <span class="checkout-basket-product__value">{{ purposeItem }}</span>
                </div>

            </div>
        </div>

        <div class="checkout-basket-product__type">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Тип товару' }}
            </span>
            <div v-for="typeItem in result?.type">
                <div>
                    <span class="checkout-basket-product__value">{{ typeItem }}</span>
                </div>

            </div>
        </div>

        <div class="checkout-basket-product__volume">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ "Об'єм" }}
            </span>
            <div>
                <span class="checkout-basket-product__value">{{ result.volume }}</span>
            </div>
        </div>

        <div class="checkout-basket-product__discount">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Знижка' }}
            </span>
            <div v-if="result.price">
                <span class="checkout-basket-product__value"> {{ result.price?.discount + '%' }} </span>
            </div>
        </div>

        <div class="checkout-basket-product__price">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Ціна' }}
            </span>
            <div v-if="result.price" class="checkout-basket-product__total">
                <DefPrice>
                    {{ result.price.printPrice }}
                </DefPrice>
                <DefPriceOld>
                    {{ result.price.printPriceOld }}
                </DefPriceOld>
            </div>
            <!--            <DefSale>-->
            <!--                {{ result.price.printDiscount }}-->
            <!--            </DefSale>-->
        </div>

        <div
            class="checkout-basket-product__count"
        >
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Кількість' }}
            </span>
            <span class="checkout-basket-product__number">
                {{ result.count + ' шт'}}
            </span>
        </div>
        <div class="checkout-basket-product__amount">
            <span class="checkout-basket-product__title" v-if="isShowTitle">
                {{ 'Сума' }}
            </span>
            <div v-if="result.price" class="checkout-basket-product__total">
                <DefPrice>
                    {{ result.price.printTotalPrice }}
                </DefPrice>
                <DefPriceOld>
                    {{ result.price.printTotalPriceOld }}
                </DefPriceOld>
            </div>
<!--            <DefSale>-->
<!--                {{ result.price.printDiscount }}-->
<!--            </DefSale>-->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'CheckoutBasketProduct',
    props: ['result'],
    computed: {
        ...mapState({
            products: ({checkout}) => checkout.data.vue.products,
        }),
        isShowTitle() {
            let isFirstCard = !!this.products.find((el, key) => key === 0 && el.id == this.result.id);
            if (this.screenWidth >= 992) {
                return isFirstCard;
            }
            return true;
        }
    },
}
</script>

<style lang="scss" scoped>
.checkout-basket-product{
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-gap: 12px 8px;
    padding: 16px 30px;


    @include point {
        grid-template-columns: auto;
        grid-auto-flow: column;
        grid-template-areas:
        "image"
        "overview"
        "article"
        "brand"
        "purpose"
        "type"
        "volume"
        "discount"
        "price"
        "count"
        "amount"
    ;
    }

    @include point-sm {
        grid-template-areas:
        "image overview overview"
        ". article brand"
        ". purpose type"
        ". volume discount"
        ". price amount"
        ". . count"
    ;
    }

    @include point-md {
        /*grid-template-columns: auto;*/
        /*grid-auto-flow: column;*/
        grid-gap: 12px 8px;
        grid-template-areas:
        "image overview overview overview overview"
        ". article brand purpose type"
        ". volume discount price count"
        ". amount . . ."
    ;
    }

    @include point-lg{
        grid-template-columns: 80px 2fr repeat(10, 1fr);
        grid-template-areas: "image overview overview article brand purpose type volume discount price count amount";
        /*align-items: flex-start;*/
        grid-column-gap: 10px;
        padding-bottom: 8px;
        max-width: 600px;
        /*min-width: 900px;*/
    }

    &__image{
        grid-area: image;
        display: grid;

        @include point-sm{
            grid-row-start: none;
        }
        @include point-lg {
            grid-row-start: unset;
        }
        @include decreasing-lg {
            min-width: 150px;
        }
    }
    &__picture{
        position: relative;
        :deep(.def-img) {
            display: inline-block;
        }
    }
    &__type{
        grid-area: type;
        min-width: 100px;
    }
    &__volume{
        grid-area: volume;
        min-width: 70px;
    }
    &__discount{
        grid-area: discount;
        min-width: 70px;
    }

    &__brand{
        grid-area: brand;
        min-width: 100px;
    }
    &__purpose{
        grid-area: purpose;
        min-width: 100px;
    }
    &__count{
        grid-area: count;
        min-width: 70px;
    }
    &__amount{
        grid-area: amount;
        min-width: 100px;
        :deep(.def-price) {
            font-weight: 600;
        }
    }
    &__total{
        display: flex;
        flex-direction: column;
        min-width: 110px;
    }
    &__name{
        /*font-weight: 600;*/
        /*font-size: rem(14);*/
        /*line-height: 100%;*/
        /*letter-spacing: 0.06em;*/
        /*text-transform: uppercase;*/
        margin-bottom: 8px;
        color: #333;
        @include point-md{
            margin-bottom: 6px;
            font-size: rem(15);
        }
    }
    &__value {
        color: #333;
        font-size: rem(15);
    }
    &__overview{
        min-width: 150px;
        grid-area: overview;
        @include point-md{
            font-size: rem(12);
            margin-bottom: 10px;
        }
    }
    &__article{
        font-size: rem(11);
        /*color: var(--color-dark-grey);*/
        grid-area: article;
        min-width: 130px;
    }
    &__title{
        font-weight: 500;
        font-size: rem(12);
        line-height: 120%;
        @include point-lg {
            margin-bottom: 30px;
        }
        @include point-md{
            /*color: var(--color-dark-grey);*/
            font-size: rem(12);
            display: inline-block;
        }
        &:after{
            content: ':';
            margin-right: 5px;
            @include point-md{
                content: '';
                margin-right: 0;
            }
        }
    }
    &__number{
        font-weight: 500;
        font-size: rem(12);
        line-height: 120%;
        @include point-md{
            font-size: rem(16);
        }
    }
    /*&__type,*/
    &__count{
        display: flex;
        @include point-md{
            flex-direction: column;
        }
    }
    &__price{
        grid-area: price;
    }

    :deep(.def-price-old){
        /*order: 2;*/
        line-height: 112%;
        width: max-content;
        @include point-md{
            font-size: rem(14);
            margin-bottom: 4px;
            /*order: 1;*/
        }
    }
    :deep(.def-price){
        font-size: rem(18);
        font-weight: 700;
        color: #333;

        /*order: 1;*/
        @include point-md{
            margin-bottom: 8px;
            /*order: 2;*/
        }
    }
    :deep(.def-sale){
        font-size: rem(9);
        @include point-md{
            font-size: rem(11);
        }
    }

}
</style>