import Helpers from '../../../libs/Helpers';
const helpers = new Helpers();

export default {
    state: () => ({
        loader: false,
        screenWidth: helpers.isInstagram() ? 320 : window.innerWidth,
        defModal: {
            isOpen: false,
            data: {}
        }
    }),
    mutations: {
        /** change screenWidth **/
        ['global/changeScreenWidth']: (state) => {
            state.screenWidth = window.innerWidth;
        },
        /**
         * @param {string|object} payload - parent selector for preloader default: document.querySelector('body').
         */
        ['global/loaderStart']: (state, payload) => {
            state.loader = true;
        },
        ['global/loaderStop']: (state) => {
            state.loader = false;
        },
        ['global/modalShow']: (state, payload) => {

            state.defModal = {
                isOpen: true,
                data: { ...payload }
            };
        },
        ['global/modalHide']: (state) => {
            state.defModal = {
                isOpen: false
            };
        }
    }
}