import Vuex from 'vuex';
import loader from './plugins/loader';
import defaultData from './plugins/defaultData';

import { createStore } from 'vuex';

// window.Vue.use(Vuex);

export const store = createStore({
    state: { results: {}},
    mutations: {
        ['store/setResultsStore'](state, result) {
            state.results = { ...state.results, [result.name]: result.value };
        },
        ['store/setStore'](state, { name, value }) {
            try {
                if (state[name]) {
                    state[name].data = { ...state[name].data, ...value }
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
    actions: {},
    getters: {
        /**
         * get LANGUAGE_ID for request params
         * @param siteLanguages
         * @returns {{LANGUAGE_ID}}
         */
        getRequestLang: ({ results: { siteLanguages } }) => {
            const activeLanguage = Object.values(siteLanguages.items).find(el => el.current);

            return { LANGUAGE_ID: activeLanguage?.code };
        },
        getCurrentLang: ({ results: { siteLanguages } }) => {
            return Object.values(siteLanguages.items).find(el => el.current);
        },
        // isProd: ({ results: { siteLanguages } }) => {
        //     return siteLanguages?.isProd;
        // },
    },
    modules: {
        global: require('./modules/global/global').default,
        checkout: require('./modules/checkout').default,
    },
    plugins: [ loader, defaultData ],
    strict: process.env.NODE_ENV !== 'production',
})
//
// export const store = new Vuex.Store({
//     state: { results: {}},
//     mutations: {
//         ['store/setResultsStore'](state, result) {
//             state.results = { ...state.results, [result.name]: result.value };
//         },
//         ['store/setStore'](state, { name, value }) {
//             try {
//                 if (state[name]) {
//                     state[name].data = { ...state[name].data, ...value }
//                 }
//             } catch (err) {
//                 console.error(err);
//             }
//         },
//     },
//     actions: {},
//     getters: {
//         /**
//          * get LANGUAGE_ID for request params
//          * @param siteLanguages
//          * @returns {{LANGUAGE_ID}}
//          */
//         getRequestLang: ({ results: { siteLanguages } }) => {
//             const activeLanguage = Object.values(siteLanguages.items).find(el => el.current);
//
//             return { LANGUAGE_ID: activeLanguage?.code };
//         }
//     },
//     modules: {
//         cabinet: require('./modules/cabinet').default,
//         personal: require('./modules/personal').default,
//         favourite: require('./modules/favourite').default,
//         global: require('./modules/global/global').default,
//         product: require('./modules/product').default,
//         cities: require('./modules/cities').default,
//         basket: require('./modules/basket').default,
//         catalog: require('./modules/catalog').default,
//         checkout: require('./modules/checkout').default,
//     },
//     plugins: [ loader, defaultData ],
//     strict: process.env.NODE_ENV !== 'production',
// });
