<template>
    <div class="def-select-wrapper">
        <input
            type="text"
            :name="name"
            :value="value"
            :required="required"
            @invalid.prevent="isInvalid = true"
            class="def-select-wrapper__input"
        >
        <div
            class="def-select-wrapper__front"
            :class="{
                'def-select-wrapper__front_active': isDefaultSlot,
                'def-select-wrapper__front_error': isInvalid
            }"
            @click="handlerToggle"
        >
            <span
                v-if="$slots.placeholder"
                class="def-select-wrapper__placeholder"
                :class="{ 'def-select-wrapper__center': !isHeader }">
                <slot  name="placeholder" />
            </span>
            <span v-if="isHeader" class="def-select-wrapper__header">
                <slot  name="header" />
            </span>
            <svg
                v-if="isDefaultSlot"
                :class="{ 'def-select-wrapper__close_active': active }"
                class="def-select-wrapper__close"
            >
                <use xlink:href="#svg-select"></use>
            </svg>
        </div>
        <SlideUpDown v-model="active" class="def-select-wrapper__drop">
            <component
                :is="screenWidth >= 992 ? 'PerfectScrollbar': 'div'"
                class="def-select-wrapper__scroll"
                @wheel.prevent
            >
                <span v-if="$slots.search" class="def-select-wrapper__search">
                    <slot name="search" />
                    <svg class="def-select-wrapper__magnifying"><use xlink:href="#svg-magnifying"></use></svg>
                </span>
                <div class="def-select-wrapper__items" @click="active = false; isInvalid= false">
                    <slot />
                </div>
            </component>
        </SlideUpDown>
    </div>
</template>

<script>
export default {
    name: "DefSelectWrapper",
    props: {
        name:{
            type: String,
        },
        value:{
            type: String,
        },
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
        },
    },
    data: () => ({
        active: false,
        isInvalid: false,
    }),
    computed: {
        isHeader() {
            return !!this.$slots?.header?.()?.find(el => el?.text?.trim() || el?.children);
        },
        isDefaultSlot() {
            return !!this.$slots?.default?.()?.find(el => el?.tag || el?.children);
        }
    },
    watch: {
        active(prevState) {
            if (prevState) {
                document.addEventListener('click', this.closeSelect);
            } else {
                document.removeEventListener('click', this.closeSelect);
            }
        },
        value(newData) {
            if (newData) this.isInvalid = false;
        }
    },
    methods: {
        closeSelect({ target }) {
            if (target.closest('.def-select-wrapper') !== this.$el) {
                this.active = false;
            }
        },
        handlerToggle() {
            if (this.isDefaultSlot) {
                this.active = !this.active;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.def-select-wrapper{
    width: 100%;
    position: relative;
    font-size: rem(14);
    @include point-md{
        font-size: rem(16);
    }
    &__front{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: #FFFFFF;
        min-height: 48px;
        position: relative;
        height: 100%;
        border-bottom: 1px solid transparent;
        transition: .2s linear border-bottom-color;
        padding-bottom: 10px;
        padding-right: 18px;
        &_active{
            border-bottom-color: currentColor;
            cursor: pointer;
        }
        &_error{
            border-bottom-color: var(--background-red);
        }
        &:hover{
            color: var(--color-link-hover-dark);
        }
    }
    &__placeholder{
        margin-bottom: 2px;
        position: absolute;
        top: 0;
        transition: .2s linear top, .2s linear font-size;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        //max-width: calc(100% - 42px);
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: rem(10);
        color: #5F5F67;
    }
    &__center{
        top: 50%;
        transform: translateY(-30%);
        font-size: rem(16);
    }
    &__drop{
        position: absolute;
        left: 0;
        //right: 0;
        top: calc(100% - 1px);
        background-color: #fff;
        z-index: 6;
        min-width: 100%;
    }
    &__scroll{
        max-height: 250px;
        overflow-y: auto;
        border: 1px solid #CACECE;
    }
    &__items{
        :deep(> *){
            display: flex;
            align-items: center;
            padding: 8px 12px;
            cursor: pointer;
            transition: .2s linear background-color;
            &:hover{
                background-color: #FAFAFA;
            }
        }
    }
    &__close{
        position: absolute;
        transition: .2s linear transform;
        right: 0;
        width: 16px;
        height: 16px;
        fill: currentColor;
        top: 50%;
        transform: translateY(-50%);
        &_active{
            transform: rotate(180deg) translateY(50%);
        }
    }
    &__search{
        display: block;
        position: relative;
        padding-top: 8px;
        :deep(input){
            width: 100%;
            height: 42px;
            border: none;
            border-bottom: 1px solid var(--background-black);
            font-weight: 500;
            font-size: rem(16);
            padding: 0 30px 0 12px;
        }
    }
    &__magnifying{
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
    }
    &__input{
        //display: none;
        width: 1px;
        opacity: 0;
        position: absolute;
        pointer-events: none;
        width: 1px;
    }
}
</style>