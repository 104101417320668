<template>
    <div class="checkout-contact-success">
        <div v-for="field in userFields.props">
            <div v-if="field.CODE === 'EMAIL'" >
                <b>{{field.NAME}}: </b>{{requestData [field.inputName]}}
            </div>
            <div v-else-if="field.CODE === 'PHONE'">
                <b>{{field.NAME}}: </b>{{requestData [field.inputName]}}
            </div>
        </div>

    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "CheckoutContactSuccess",
        computed: {
            ...mapState({
                userFields: ({checkout}) => checkout.data.vue?.userFields,
                requestData: ({checkout}) => checkout.data.vue?.requestData,
            }),
        },
    }
</script>

<style lang="scss" scoped>
.checkout-contact-success {
    padding: 30px 35px 25px 35px;
    @include point-md {
        padding: 30px 35px 25px 85px;
        //max-width: 480px;
    }
}
</style>