<template>
    <div class="checkout-contact">
        <div v-for="item in userFields">
<!--           item.VALUE-->
            <DefEmail
                v-if="item.CODE === 'EMAIL'"
                :name="item.inputName"
                :value="requestData[item.inputName]"
                :label="item.NAME"
                :required="item.REQUIRED === 'Y'"
                :key="item.CODE"
                :placeholder="item.NAME"
                :errorText="`Поле E-Mail обов'язково для заповнення`"
                @change="({target}) => setRequestData({ name: target.name, value: target.value })"
            />
            <div v-else-if="item.CODE === 'PHONE'">
                <DefPhone
                    :name="item.inputName"
                    :value="item.VALUE"
                    :label="item.NAME"
                    :required="item.REQUIRED === 'Y'"
                    :key="item.CODE"
                    :placeholder="item.NAME"
                    @change="({target}) => changeData(target.name, target.value)"

                />
            </div>

            <DefInput
                    v-else
                    :name="item.inputName"
                    :value="requestData[item.inputName]"
                    :label="item.NAME"
                    :required="item.REQUIRED === 'Y'"
                    :key="item.CODE"
                    :placeholder="item.NAME"
                    @change="({target}) => setRequestData({ name: target.name, value: target.value })"
            />

        </div>

        <DefTextArea
                :value="requestData['ORDER_DESCRIPTION']"
                @change="({target}) => this.setRequestData({ name: 'ORDER_DESCRIPTION', value: target.value})"
                :placeholder="'Коментарі до замовлення:'"
        />
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';

export default {
    name: 'CheckoutContact',
    props: ['result'],
    computed: {
        ...mapState({
            userFields: ({checkout}) => checkout?.data?.vue?.userFields?.props,
            requestData: ({checkout}) => checkout.data.vue?.requestData,
            messages: ({checkout}) => checkout.data.vue.messages,
        }),
    },
    methods: {
        ...mapMutations({
            setRequestData: 'checkout/setRequestData',
        }),
        ...mapActions({
            setAjax: 'checkout/setAjax',

        }),
        async changeData(name, value) {
            if (value) {
                this.setRequestData({name, value});
                this.setAjax();
            }
        },
    },

    watch: {

    },

}
</script>

<style lang="scss" scoped>
.checkout-contact {
    :deep(.def-input),
    :deep(.def-email),
    :deep(.def-text-area),
    :deep(.def-phone) {
        width: 100%;
        margin-bottom: 30px;
    }
    :deep(.def-input__placeholder),
    :deep(.def-email__placeholder),
    :deep(.def-text-area__placeholder),
    :deep(.def-phone__placeholder) {
        top: -25px;
    }
    :deep(.def-checkbox) {
        margin-bottom: 30px;
    }

}
</style>