<template>
    <section class="def-slider">
        <div v-if="nav" ref="prev" class="def-slider__nav def-slider__prev">
            <slot name="nav-prev">
                <span class="def-slider__default-button" v-if="!this.slider.isBeginning">
                    <svg><use xlink:href="#svg-arrow-left"></use></svg>
                </span>
            </slot>
        </div>
        <div ref="slider" class="swiper-container">
            <div class="swiper-wrapper">
                <slot/>
            </div>
        </div>
        <!-- If we need scrollbar -->
        <div v-if="scrollbar" ref="scrollbar" class="swiper-scrollbar"></div>
        <!-- If we need pagination -->
        <div v-if="pagination" ref="pagination" class="swiper-pagination"></div>
        <div v-if="nav" ref="next" class="def-slider__nav def-slider__next">
            <slot name="nav-next">
                <span class="def-slider__default-button" v-if="!this.slider.isEnd">
                    <svg><use xlink:href="#svg-arrow-right"></use></svg>
                </span>
            </slot>
        </div>
    </section>
</template>

<script>

export default {
    name: 'DefSlider',
    props: ['settings', 'scrollbar', 'nav', 'pagination',],
    data() {
        return {
            slider: new Swiper,
        }
    },
    methods: {
        createSlider() {
            let _this = this;
            this.slider = new Swiper(this.$refs.slider, {
                navigation: {
                    nextEl: this.$refs.next,
                    prevEl: this.$refs.prev,
                },
                pagination: {
                    el: this.$refs.pagination,
                    clickable: true,
                },
                scrollbar: {
                    el: this.$refs.scrollbar,
                    draggable: false,

                },
                ...this.settings,
                on:{
                    slideChangeTransitionStart(){
                        _this.$emit('updateSlide', this )
                    }
                }
            });
        },
    },

    updated() {
        this.slider.update(true);


    },
    // created(){
    //     console.log("created ===>", this.slider)
    // },
    mounted() {
        this.createSlider();
        this.$emit('updateSlide', this.slider )

        // console.log("data -------->", this.slider);
    },
    beforeDestroy() {
        this.slider.destroy();
    },
}
</script>

<style lang="scss">
.def-slider {
    position: relative;

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        cursor: pointer;

        -webkit-user-select: all; /* Chrome 49+ */
        -moz-user-select: all; /* Firefox 43+ */
        -ms-user-select: all; /* No support yet */
        user-select: all; /* Likely future */
    }

    &__next {
        right: 0;
    }

    &__default-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 50%;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    div.swiper-scrollbar {
        background: #F2F2F2;
        width: 100%;
        border-radius: 4px;
        height: 8px;
        position: static;
        margin-top: 16px;
    }

    .swiper-scrollbar-drag {
        background-color: var(--color-red);
        border-radius: 4px;
    }

    .swiper-slide {
        height: unset;
    }

    .swiper-wrapper {
        align-self: stretch;
    }

    .swiper-pagination-bullet {
        width: 10%;
        height: 2px;
        background-color: #85898B;
        opacity: 0.4;
        border-radius: 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: var(--color-red);
        opacity: 1;
    }
}
</style>