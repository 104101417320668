<template>
    <div class="checkbox-checkout">
        <div class="checkbox-checkout__agreement" v-if="errorText && !isValid">{{ errorText }}</div>
        <label class="def-checkbox-checkout" >
            <input
                    :name="name"
                    :required="required"
                    :value="value"
                    :checked="checked"
                    :disabled="disabled"
                    :errorText="errorText"
                    type="checkbox"
                    class="def-checkbox-checkout__input"
                    @change="handlerChange"
                    @invalid.prevent="handlerInvalid"
                    @blur="handlerBlur"

            >
            <span class="def-checkbox-checkout__front" :class="{ 'def-checkbox-checkout__front-checked': checked }">
<!--            <svg class="def-checkbox-checkout__icon"><use xlink:href="#svg-check"></use></svg>-->
        </span>
            <component :is="tag" v-if="$slots.default" class="def-checkbox-checkout__text">
                <slot/>
            </component>


        </label>
    </div>

</template>

<script>
    export default {
        name: 'DefCheckboxCheckout',
        selector: '.def-checkbox-checkout',
        data() {
            return {
                isValid: true,
            }
        },
        props: {
            'name': String,
            'required': Boolean,
            'value': [Boolean, Number, String],
            'checked': Boolean,
            'disabled': Boolean,
            'errorText': String,
            'tag': {
                type: String,
                default: 'span',
            }
        },
        methods: {
            handlerInvalid(e) {
                const { target } = e;
                this.isValid = false;
                target.classList.add('def-checkbox-checkout__input_invalid');
                this.$emit('invalid', e);
            },
            handlerChange(event) {
                const target = event.target;
                if (target.value == 'N') {
                    target.classList.remove('def-checkbox-checkout__input_invalid');
                    this.isValid = true;
                }

                // this.$emit('change', event); // не работает - @todo разобраться

            },

            handlerBlur(e) {
                const target = e.target;
                if (target.checkValidity()) {
                    target.classList.remove('def-checkbox-checkout__input_invalid');
                    this.isValid = true;
                } else {
                    // target.classList.add('def-checkbox-checkout__input_invalid');
                }
                this.$emit('blur', e); // не работает - @todo разобраться
                // console.log(this.isValid)
            },

        },
        mounted() {
            // console.log(this.$slots?.default()[0].type);
        }
    }
</script>

<style lang="scss" scoped>
    .checkbox-checkout {
        position: relative;
        display: inline-flex;
        margin-bottom: 25px;
        &__agreement {
            position: absolute;
            top: -20px;
            right: 0px;
            font-size: rem(12);
            color: red;
        }
    }
    .def-checkbox-checkout {
        display: inline-flex;
        cursor: pointer;
        /*box-shadow: 0 3px 6px 0 rgba(0,0,0,.3);*/
        /*transition: left .1s ease-in;*/
        /*height: 18px;*/
        /*width: 33px;*/
        /*border-radius: 10px;*/
        &__input {
            display: none;

            &:checked + .def-checkbox-checkout__front {
                background-color: var(--background-green);
                /*border-color: var(--background-black);*/

                .def-checkbox-checkout__icon {
                    fill: #fff;
                }
            }

            &:disabled + .def-checkbox-checkout__front,
            &:disabled + .def-checkbox-checkout__front + .def-checkbox-checkout__text {
                cursor: context-menu;
            }
            &_invalid + .def-checkbox-checkout__front{
                border: 1px solid #ec2127;
            }
        }

        &__front {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;
            transition: .2s linear border-bottom-color, .2s linear background-color;
            top: 4px;
            width: 33px;
            min-width: 33px;
            border-radius: 10px;
            /*background: #e0e0e0;*/
            background: var(--background-light-grey);

            &:after {
                position: absolute;
                display: flex;
                top: 0px;
                content: "";
                bottom: 0;
                border-radius: 100%;
                background: #fff;
                width: 14px;
                height: 14px;
                border: none;
                margin: 2px;
                left: 0.45px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .3);
                transition: left .1s ease-in;
                /*z-index: 1;*/
            }

            &-checked {
                &:after {
                    left: 15px;
                }
            }

        }

        &__icon {
            display: block;
            height: 18px;
            width: 18px;
            fill: transparent;
            transition: .2s linear fill;
        }

        &__text {
            margin-left: 10px;
            color: #999;
            position: relative;
            top: -3px;

        }
        &__invalid{
            border-bottom-color: var(--background-red);
        }


    }
</style>