<template>
    <span class="def-img-wrapper">
        <Transition :name="transition && 'brightness'">
            <img
                v-if="result"
                :src="loadImage ? result.src : getBasePreview"
                :alt="result.alt"
                :style="imageStyle"
                :width="result.width"
                :height="result.height"
                :key="result.src"
                class="def-img"
                @load="$event.target.style.color='var(--main-color)'"
                @error="$event.target.style.color='var(--main-color)'"
            >
        </Transition>
    </span>
</template>

<script>
export default {
    name: 'DefImg',
    props: {
        result: Object,
        maxWidth: String,
        maxHeight: String,
        maxSizeOff: {
            type: Boolean,
            default: false,
        },
        transition: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            intersectionObserver: null,
            options: {
                rootMargin: '100px',
                threshold: .01,
            },
            loadImage: false,
        }
    },
    computed: {
        getBasePreview() {
            try {
                let canvas = document.createElement('canvas');
                canvas.width = this.result.width;
                canvas.height = this.result.height;
                return canvas.toDataURL();
            } catch (err) {
                console.error(err);
            }
        },
        imageStyle() {
            try {
                if (!this.maxSizeOff) {
                    if (this.result?.height > this.result?.width) {
                        return `max-height: ${ this.maxHeight ?? '100%' }; width: unset; height: unset;`;
                    } else {
                        return `max-width: ${ this.maxWidth ?? '100%' }; height: unset;`;
                    }
                }
                return '';
            } catch (err) {
                console.error(err);
            }
        },
    },
    methods: {
        observerCallBack (entries) {
            try {
                // If intersectionRatio is 0, the target is out of view
                // and we do not need to do anything.
                // console.log(entries[0].intersectionRatio);
                if (entries[0].intersectionRatio <= 0) return;

                this.loadImage = true;
                this.intersectionObserver.unobserve(this.$el);
            } catch (err) {
                console.error(err);
            }
        }
    },
    mounted() {
        try {
            if (this.result && !this.intersectionObserver) {
                if ('IntersectionObserver' in window) {
                    // LazyLoad images using IntersectionObserver
                    this.intersectionObserver = new IntersectionObserver(this.observerCallBack, this.options);
                    this.intersectionObserver.observe(this.$el);
                }  else {
                    // Load all images at once
                    this.loadImage = true;
                }
            }
        } catch (err) {
            console.error(err);
        }
    },
    beforeUnmount() {
        try {
            if (
                'IntersectionObserver' in window &&
                this.result &&
                this.intersectionObserver
            ) {
                this.intersectionObserver.unobserve(this.$el);
            }
        } catch (err) {
            console.error(err);
        }
    }
}
</script>

<style lang="scss" scoped>
.def-img-wrapper{
    position: relative;
    display: block;
}
.def-img{
    display: block;
    height: auto;
    width: 100%;
    color: transparent;
}

@keyframes brightness {
    0% {
        opacity: 0;
        //filter: sepia(80%) saturate(1.5);
    }
    54% {
        //filter: sepia(30%) saturate(1.3);
    }
    //58% {
    //
    //}
    100% {
        filter: none;
        opacity: 1;
        //z-index: 1;
    }
    //60% {
    //    opacity: 0;
    //    z-index: -1;
    //}
}
.brightness-enter-from{
    opacity: 0;
    z-index: 1;
    filter: none;
}

.brightness-enter-active{
    transition: .5s linear all;
}
.brightness-enter-to{
    opacity: 1;
    animation: brightness .5s;
    z-index: -1;
}

.brightness-leave-from{
    z-index: -1;
    opacity: 1;
}
.brightness-leave-active{
    transition: .5s linear all;
    opacity: 0;
    position: absolute;
}
.brightness-leave-to{
    opacity: 0;
    z-index: -1;
}
</style>