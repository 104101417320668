<template>
    <DefSelectSearch
            :required="true"
            :value="NovaPostCity?.printName"
    >
        <template #header>
            <!--                {{ requestData[ getCityInputName ] }}-->
            <!--                {{UkrPostCity.printName}}-->
            {{ NovaPostCity?.printName }}
        </template>
        <template #placeholder>
            {{ 'Місто' }}
        </template>
        <template #search>
            <input
                    type="text"
                    class="autofocus"
                    @input="({target}) => handlerInput(target.value)"
                    :placeholder="'Виберіть місто'"
            >
        </template>
        <div
                v-for="city in cities"
                @click="changeCity({city, key: 'NP'})"
        >
            {{ city.printName }}
        </div>
    </DefSelectSearch>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    export default {
        name: "CheckoutDeliveryNovaPostCity",
        props: ['field'],
        computed: {
            ...mapState({
                cities: ({checkout}) => checkout.data?.NovaPostCities,
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                NovaPostCity: ({checkout}) => checkout.data?.NovaPostCity,
            }),

        },
        methods: {
            ...mapActions({
                setAjax: 'checkout/setAjax',
                getCities: 'checkout/getCities',
                changeCity: 'checkout/changeCity',
            }),

            handlerInput(value) {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => this.getCities({city: value, key: 'NovaPost'}), 300);

            },
        }

    }
</script>

<style scoped>

</style>