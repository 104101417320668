<template>
    <div class="checkout-pay-system-success">
        <div>
            <b>
                <span>{{ paySystem.NAME }}</span>
            </b>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "CheckoutPaySystemSuccess",
        computed: {
            ...mapState({
                paySystems: ({checkout}) => checkout.data.vue?.paySystem.items,
            }),
            paySystem() {
                return Object.values(this.paySystems)?.find(el => el.CHECKED === 'Y');
            }
        },
    }
</script>

<style lang="scss" scoped>
    .checkout-pay-system-success {
        padding: 30px 35px 25px 35px;
        @include point-md {
            padding: 30px 35px 25px 85px;
            //max-width: 480px;
        }
    }
</style>