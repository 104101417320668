<template>
    <label class="def-email">
        <span
            class="def-email__placeholder"
            :class="{ 'def-email__placeholder_center': !saveValue.length && !isFocus }"
        >
            {{ placeholder }}
        </span>
<!--        :placeholder="label"-->
        <input
            :type="type"
            :name="name"
            :required="required"
            :value="saveValue"
            :autocomplete="autocomplete"
            :id="id"
            :form="form"
            :class="{ 'def-email__invalid': !isValid || isError}"
            @input="handlerInput"
            @change="$emit('change', $event)"
            @click="$emit('click', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            @invalid.prevent="handlerInvalid"
            @blur="handlerBlur"
            @focus="handlerFocus"
            class="def-email__input"
            pattern="^([a-z0-9_\-]+\.)*[a-z0-9_\-]+@[a-z0-9_\-]+(\.[a-z0-9_\-]+)*\.[a-z]{2,6}$"
            ref="email"
            :errorText="errorText"
        >
        <transition name="fade">
            <svg
                v-if="!isValid || isError"
                class="def-email__icon-error"
            ><use xlink:href="#svg-error-icon"></use></svg>
        </transition>
        <transition name="fade">
            <span
                v-if="!isValid"
                class="def-email__error"
            >
                <slot />
            </span>
            <span
                v-else-if="isError"
                class="def-email__error"
            >
                <slot name="error"/>
            </span>
        </transition>
    </label>
</template>

<script>
export default {
    name: 'DefEmail',
    props: {
        name: String,
        placeholder: String,
        required: Boolean,
        autocomplete: Boolean,
        value: {
            type: String,
            default: '',
        },
        id: String,
        label: String,
        form: String,
        type: {
            type: String,
            default: 'text',
        },
        isError: Boolean,
        errorText: String,
    },
    data() {
        return {
            isValid: true,
            isFocus: false,
            saveValue: this.value,
        }
    },
    methods: {
        handlerInput(e) {
            // console.log(e.target.value);
            this.saveValue = e.target.value;
            this.$emit('input', e);
        },
        handlerInvalid(e) {
            if (this.required) {
                this.isValid = false;
            }

            this.$emit('invalid', e);
        },
        handlerBlur(e) {
            const target = e.target;
            this.saveValue = target.value;
            if (target.checkValidity() && this.required) {
                this.isValid = true;
            }
            this.isFocus = false;
            this.$emit('blur', e);
        },
        handlerFocus(e) {
            this.isFocus = true;
            this.$emit('focus', e);
        }
    },
    watch: {
        value(newValue) {
            this.saveValue = newValue;
        }
    },
    mounted() {
        const email = new Inputmask({
            mask: '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@{1}*{1,20}d{1}*{2,6}[.*{1,5}]',
            greedy: false,
            definitions: {
                '*': {
                    validator: "[0-9A-Za-z_-]",
                    cardinality: 1,
                    casing: "lower"
                },
                '@': {
                    validator: '@',
                    cardinality: 1,
                    casing: 'lower'
                },
                'd': {
                    validator: '.',
                    cardinality: 1,
                    casing: 'lower'
                }
            }
        });
        email.mask(this.$refs.email);
    }
}
</script>

<style lang="scss" scoped>
.def-email{
    display: inline-block;
    position: relative;
    height: 56px;
    &__placeholder{
        position: absolute;
        top: 0px;
        font-weight: 500;
        font-size: rem(12);
        color: var(--color-dark-grey);
        transition: .2s linear top;
        &_center{
            font-size: rem(12);
            top: 14px;
            left: 1px;
        }
    }
    &__input{
        /*font-weight: 500;*/
        font-size: rem(16);
        height: 48px;
        border: 1px solid #c8c7c7;
        border-radius: 3px;
        padding: 6px 20px 0 10px;
        transition: .2s linear border-bottom-color;
        width: 100%;
    }
    &__error{
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: rem(10);
        line-height: 100%;
        color: var(--color-red);
    }
    &__invalid{
        border-bottom-color: var(--background-red);
    }
    &__icon-error{
        position: absolute;
        top: 13px;
        right: 0;
        fill: var(--background-red);
        width: 16px;
        height: 16px;
    }
}
</style>