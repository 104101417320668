<template>
    <div class="checkout-basket-header">
<!--        <DefTitle :result="2">-->
<!--            <h2>{{ messages.yourOrder }}</h2>-->
<!--        </DefTitle>-->
<!--        <DefLink @click="changeOpenSmallBasket(true)">-->
<!--            {{ messages.edit }}-->
<!--        </DefLink>-->
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
    name: 'CheckoutBasketHeader',
    computed: {
        ...mapState({
            messages: ({ checkout }) => checkout?.data?.vue?.messages,
        })
    },

}
</script>

<style lang="scss" scoped>
.checkout-basket-header{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    @include point-md{
        flex-direction: row;
    }
    :deep(.def-title){
        margin-bottom: 16px;
        @include point-md{
            margin-bottom: 0;
        }
    }
    :deep(.def-link){
        @include point-md{
            margin-left: 16px;
        }
    }
}
</style>