<template>
    <DefSelectSearch
            :required="true"
            :value="requestData[ getStreetInputName ]"
    >
        <template #header>
            {{ requestData[ getStreetInputName ] }}
        </template>
        <template #placeholder>
            {{ 'Вулиця' }}
        </template>
        <template #search>
            <input
                    type="text"
                    class="autofocus"
                    @input="({target}) => handlerInput(target.value)"
                    :placeholder="'Виберіть Вулицю'"
            >
        </template>
        <div
                v-for="street in streets"
                @click="changeStreet(street)"
        >
            {{ street.printName }}
        </div>
    </DefSelectSearch>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    export default {
        name: "CheckoutDeliveryUkrPostStreet",
        props: ['field'],
        computed: {
            ...mapState({
                streets: ({checkout}) => checkout.data?.UkrPostStreets,
                UkrPostCity: ({checkout}) => checkout.data?.UkrPostCity,
                deliveryProps: ({checkout}) => checkout.data.vue.delivery.props,
                requestData: ({checkout}) => checkout.data.vue?.requestData,
            }),
            getStreetInputName() {
                return Object.values(this.deliveryProps)?.find(el => el.CODE === 'STREET')?.inputName;
            },
        },
        methods: {
            ...mapActions({
                setAjax: 'checkout/setAjax',
                getStreets: 'checkout/getUkrPostStreets',
                changeStreet: 'checkout/changeUkrPostStreet',
            }),
            updateStreets(value) {
                this.getStreets({
                    cityId: this.UkrPostCity?.UF_CITY_ID,
                    street: value,
                });
            },
            handlerInput(value) {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => this.updateStreets(value), 300);
            },
        }
    }
</script>

<style scoped>

</style>