<template>
    <div class="checkout-delivery-nova-post">
        <div>Доставка Новою Поштою</div>
        <DefImg :result="result.picture"/>
        <div
                v-for="item in result.ITEMS"
                :key="item.CODE"
                class="checkout-delivery-nova-post__block"
        >
            <div class="checkout-delivery-nova-post__header">
                <DefRadio
                        :name="item.CODE"
                        :checked="item.CHECKED"
                        @change="({ target }) => changeData(target)"
                >
                    {{ item.NAME }}
                </DefRadio>

            </div>
        </div>
        <CheckoutDeliveryNovaPostFields :result="result" />
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex';
    export default {
        name: "CheckoutDeliveryNovaPost",
        props: ['result'],

        computed: {
            ...mapState({
                delivery: ({ checkout }) => checkout.data.vue?.delivery,
            }),
        },
        methods: {
            ...mapMutations({
                setRequestData: 'checkout/setRequestData'
            }),
            ...mapActions({
                setAjax: 'checkout/setAjax',
            }),
            changeData(target) {
                this.setRequestData(
                    { name: this.result.npTypeKey, value: target.name },
                );
                this.setAjax();
            },
        },
    }
</script>

<style lang="scss" scoped>
.checkout-delivery-nova-post {
    padding: 20px 24px;
    border: 1px solid var(--border-light-grey);
    :deep(.def-img-wrapper) {
        height: 90px;
        padding: 15px 0;
        display: inline-flex;
    }
}
</style>