<template>
    <span class="def-sale">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'DefSale',
    selector: '.def-sale',
}
</script>

<style lang="scss" scoped>
.def-sale{
    font-weight: 600;
    font-size: rem(11);
    line-height: 110%;
    color: var(--color-red);
}
</style>