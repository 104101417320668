<template>
    <div
        class="def-title"
        :class="{
            'def-title-1': result == 1,
            'def-title-2': result == 2,
            'def-title-3': result == 3,
            'def-title-center': center,
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DefTitle',
    selector: '.def-title',
    props: ['result', 'center'],
}
</script>

<style lang="scss" scoped>
.def-title{
    margin-bottom: 24px;
    :deep( > *){
        font-weight: 600;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}
.def-title-1{
    margin-bottom: 24px;
    :deep( > *){
        font-size: rem(18);
        margin-bottom: 0;
        line-height: 120%;
        @include point-md{
            font-size: rem(24);
            line-height: 125%;
        }
    }
}
.def-title-2{
    :deep( > *){
        font-size: rem(16);
        @include point-md{
            font-size: rem(18);
            line-height: 120%;
        }
    }
}
.def-title-3{
    :deep( > *){
        font-size: rem(14);
        line-height: 130%;
        @include point-md{
            font-size: rem(16);
            line-height: 135%;
        }
    }
}
.def-title-center{
    text-align: center;
}
</style>