<template>
    <div class="checkout-basket">
        <!--        <CheckoutBasketHeader />-->

        <DefDragScroll @dragScroll="dragScroll" class="checkout-basket__wrapper">
            <div
                    ref="fixed"
                    :class="{ 'compare__top_fixed': fixed }"
                    :style="fixed ? `width: calc(100vw - ${ scrollWidth }px)` : ''">
                <div
                        v-for="(item, key) in products"
                        :key="item.id"
                        class="checkout-basket__wrapper--item"
                >
                    <CheckoutBasketProduct
                            :result="item"
                            :class="{ 'checkout-basket__first-product': key === 0 }"
                    />
                </div>

            </div>
        </DefDragScroll>

    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';

    export default {
        name: 'CheckoutBasket',
        data() {
            return {
                scrollWidth: window.innerWidth - document.documentElement.clientWidth,
                fixed: false,
                fixedOffsetTop: 0,
                fixedOffsetHeight: 0,
            }
        },
        computed: {
            ...mapState({
                products: ({checkout}) => checkout.data.vue.products,
                // messages: ({ checkout }) => checkout?.data?.vue?.messages,
            })
        },
        methods: {
            dragScroll({scrollTop, scrollLeft}) {
                if (this.fixed) {
                    const fixed = this.$refs?.fixed;
                    fixed.scrollTop = scrollTop;
                    fixed.scrollLeft = scrollLeft;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-basket {
        /*border: 1px solid var(--border-grey);*/
        /*padding: 16px;*/
        /*width: 700px;*/

        //@include point-md {
            /*padding: 24px;*/
        /*}*/

        &__wrapper {
            @include point-lg {
                overflow-x: auto;
            }
            &--item:not(:last-child) {
                border-bottom: 1px solid var(--border-light-grey);
                width: fit-content;
            }
        }
        &__first-product {
            @include point-md{
                align-items: baseline;
            }
        }
    }

</style>