<template>
    
</template>

<script>
    import Analytics from "../../utils/Analytics";
    export default {
        name: "CheckoutPurchase",
        props: ['result'],

        beforeMount() {
            Analytics.confirmCheckout(this.result)
        },
    }
</script>

<style scoped>

</style>