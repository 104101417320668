<template>
    <label class="def-phone">
        <span
            class="def-phone__placeholder"
            :class="{ 'def-phone__placeholder_top' : saveValue && saveValue.length > 0 }"
        >{{ label }}</span>
        <input
            type="text"
            class="def-phone__input"
            :name="name"
            :required="required"
            :readonly="readonly"
            v-model="saveValue"
            :class="{ 'def-phone__input_invalid': !isValid || showError}"
            :placeholder="placeholder"
            pattern="\+38\s0[0-9]{2}\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}"
            @invalid.prevent="handlerInvalid"
            @blur="handlerBlur"
            ref="phone"
        >
        <transition name="fade">
            <svg
                v-if="!isValid"
                class="def-phone__icon-error"
            ><use xlink:href="#svg-error-icon"></use></svg>
        </transition>
        <span
            v-if="required && !isValid || showError"
            class="def-phone__invalid"
        >{{ invalidText }}</span>
    </label>
</template>

<script>
export default {
    name: 'DefPhone',
    props: {
        name: String,
        placeholder: {
            type: String,
            default: '+38 XXX XXX XX XX'
        },
        required: Boolean,
        value: String,
        invalidText: String,
        label: String,
        readonly: {
            type: Boolean,
            default: false
        },
        showError: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            saveValue: this.value,
            isValid: true,
        }
    },
    methods: {
        handlerInvalid() {
            this.isValid = false;
        },
        handlerBlur(e) {
            this.saveValue = e.target.value;
            if (e.target.checkValidity()) {
                this.isValid = true;
            }
            this.$emit('blur', e);
        },
    },
    watch: {
        value(newValue) {
            this.saveValue = newValue;
        }
    },
    mounted() {
        const phone = new Inputmask({
            mask: '+38 `{1}~{1}^{1} ^{3} ^{2} ^{2}',
            clearMaskOnLostFocus: true,
            definitions: {
                '#': '',
                '^': {
                    validator: "[0-9\\s]",
                    cardinality: 1,
                    casing: "lower"
                },
                '`': {
                    validator: "[0]",
                    cardinality: 1,
                    casing: "lower"
                },
                '~': {
                    validator: "[1-9]",
                    cardinality: 1,
                    casing: "lower"
                },
            },
            placeholder: "X",
        });

        phone.mask(this.$refs.phone)
    }
}
</script>

<style lang="scss" scoped>
.def-phone{
    display: block;
    position: relative;
    padding-bottom: 16px;
    &__input{
        height: 48px;
        border: 1px solid #c8c7c7;
        border-radius: 3px;
        padding: 10px 0 0 10px;
        width: 100%;
        &_invalid{
            border-bottom-color: #C80024;
        }
    }
    &__placeholder{
        position: absolute;
        top: 0;
        font-weight: 500;
        font-size: rem(12);
        color: var(--color-dark-grey);
    }
    &__invalid{
        position: absolute;
        bottom: 4px;
        left: 0;
        font-size: rem(10);
        line-height: 100%;
        color: var(--color-red);
    }
    &__icon-error{
        position: absolute;
        top: 13px;
        right: 0;
        fill: var(--background-red);
        width: 16px;
        height: 16px;
    }
}
</style>