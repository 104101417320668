__webpack_public_path__ = '/local/templates/aspro_max/';
console.log(process.env.NODE_ENV);

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
window.axios.defaults.transformRequest = function (data, headers) {
    try {
        if (data) {
            /**
             * @alarm
             */
            // const lang = App.store.getStoreContext().getters.getRequestLang;
            // const langName = Object.keys(lang)[0];

            if (data instanceof FormData === false) {
                let formData = new FormData();

                // /** if language id is missing It'll be added **/
                // if (!data[ langName ]) {
                //     data[ langName ] = lang[ langName ];
                // }

                Object.entries(data).forEach(([key, value]) => {
                    if (typeof value === 'object') {
                        // create multidimensional array
                        for(let i in value) {
                            formData.append(`${key}[${i}]`, value[i] || null);
                        }
                    } else if (value instanceof File) {
                        // add file type
                        formData.append(key, value, value.name);
                    } else {
                        formData.append(key, value);
                    }
                })
                return formData;
            } else if (data instanceof FormData === true) {
                /** if language id is missing It'll be added **/
                // if (!data.get(langName)) {
                //     data.set(langName, lang[ langName ]);
                // }
            }
        }
        return data;
    } catch (err) {
        console.error(err);
    }
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// window.$ = window.jQuery = require('jquery');
window.App = require('./libs/App').default;

window.Inputmask = require('inputmask').default;    // маски

import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);
window.Swiper = Swiper;
//
// window.dataLayer = window.dataLayer || [];