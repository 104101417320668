import { store } from "../../store";

export default {
    computed: {
        screenWidth() {
            return store.state.global.screenWidth;
        },
        getDeviceName() {
            try {
                const screenWidth = store.state.global.screenWidth;

                if (screenWidth >= 992) {
                    return 'desktop';
                } else if (screenWidth >= 768) {
                    return 'tablet';
                }
                return 'mobile';
            } catch (err) {
                console.error(err);
            }
        }
    }
}