<template>
    <div class="checkout-block" :class="{'checkout-block__active': step === activeStep}">
        <div class="checkout-block__header"
             :style="`--url: ${ backgroundImageUrl }; --backgroundPosition: ${ backgroundPosition }; --backgroundColor: ${ backgroundColor }`"
             @click="$emit('activate', step)"
        >
            <h2 class="checkout-block__title">
                <slot name="header"/>
            </h2>
        </div>

        <SlideUpDown v-if="isOpenCheckoutBlock" v-model="isOpenCheckoutBlock" style="height: auto;">
            <div
                    class="checkout-block__content"
                    :class="{ 'checkout-block__content_cut': !full }"
            >
                <slot/>
            </div>

            <div class="checkout-block__footer">
                <div class="checkout-block__footer-buttons">
                    <div class="checkout-block__footer-buttons__back">
                        <DefButtonSecondary v-if="step !== 1 && activeStep === step"
                                            @click="$emit('activate', step - 1)">
                            {{ 'Назад' }}
                        </DefButtonSecondary>
                    </div>

                    <div class="checkout-block__footer-buttons__next">
                        <DefButtonPrimary v-if="step !== steps" @click="$emit('activate', step + 1)">
                            {{ 'Далі' }}
                        </DefButtonPrimary>
                    </div>
                </div>
            </div>
        </SlideUpDown>

        <div v-else>
            <slot name="success"/>
        </div>


    </div>
</template>

<script>
    import {mapState, mapMutations} from 'vuex';

    export default {
        name: 'CheckoutBlock',
        props: {
            full: {
                type: Boolean,
                default: false
            },
            step: {
                type: Number,
                default: 1
            },
            backgroundImage: {
                type: URL,
                default: '/local/templates/aspro_max/images/checkout_icons_grey.png'
            },
        },
        data() {
            return {
                errors: [],
                steps: 4,
                backgroundImageUrl: 'url(\'' + this.backgroundImage + '\')',
                backgroundPosition: '0 -30px'
            }
        },
        computed: {
            ...mapState({
                activeStep: ({checkout}) => checkout.data.activeStep,
            }),
            isOpenCheckoutBlock() {
                return this.step === this.activeStep || this.step === 4;
            },
            backgroundColor() {
                return this.activeStep > this.step ? '#52c200' : '#6fa287';
            },
            backgroundPosition() {
                if (this.activeStep > this.step) {
                    return '-60px -30px';
                } else {
                    switch (this.step) {
                        case 2:
                            return '-30px 0';
                        case 3:
                            return '-60px 0';
                        case 4:
                            return '-30px -30px';
                        default:
                            return '0 -30px';
                    }
                }
            }
        },
        methods: {
            ...mapMutations({
                setDataByType: 'checkout/setDataByType',
            }),
            // setActiveStep(step) {
            //     this.setDataByType({ type: 'activeStep', data: step })
            // },
        },

        mounted() {
            // this.isOpenCheckoutBlock = this.step === 1;
        }
    }
</script>

<style lang="scss" scoped>
    .checkout-block {
        border: 1px solid var(--border-light-grey);

        &__header {
            display: flex;
            align-items: center;
            background-color: #fafafa;
            padding: 30px 35px;
            cursor: pointer;
            /*border-bottom: 1px solid var(--border-light-grey);*/
            /*margin-bottom: 24px;*/
            @include point-md {
                /*padding: 30px 35px;*/
                /*transform: translate(-8px, -50%);/*
                /*padding: 0 8px;*/
                /*margin-bottom: 8px;*/
            }

            &:before {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                color: #fff;
                font-weight: 600;
                font-size: rem(16);
                line-height: 100%;
                margin-right: 20px;
                background-image: var(--url);
                background-repeat: no-repeat;
                background-color: var(--backgroundColor);
                content: "";
                background-position: var(--backgroundPosition);
            }
        }

        &__title {
            /*font-weight: 600;*/
            font-family: Montserrat, Arial, sans-serif;
            font-size: rem(20);
            /*letter-spacing: 0.04em;*/
            /*text-transform: uppercase;*/
            margin-bottom: 0;

        }

        &__content {
            padding: 30px 35px 25px 35px;
            @include point-md {
                padding: 30px 35px 25px 85px;
                //max-width: 480px;
            }

            &_cut {
                @include point-md {
                    //padding: 0 48px;
                    //max-width: calc(480px + 48px);
                    /*max-width: 568px;*/
                }
            }
        }

        &__content:has(.checkout-basket) {
            padding: unset;
        }

        &__footer {
            border-top: 1px solid var(--border-light-grey);
            padding: 25px 35px;

            :deep(.def-button-primary),
            :deep(.def-button-secondary) {
                border-radius: 26px;
                min-width: 80px;
            }

            &-buttons {
                display: flex;
                justify-content: center;

                &__back {
                    margin-right: auto;
                }

                &__next {
                    margin-left: auto;
                }
            }
        }

        :deep(.slide-up-down__container) {
            max-width: 858px;
        }

    }
</style>