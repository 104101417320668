<template>
    <div class="checkout">
<!--        <DefBreadCrumbs />-->

<!--        <DefTitle v-if="messages" :result="1">-->
<!--            <h1>{{ messages.orderTitle }}</h1>-->
<!--        </DefTitle>-->

<!--        handlerSaveOrder-->
        <form class="checkout__grid" ref="checkoutForm" @submit.prevent="handlerSaveOrder">
            <main class="checkout__main">
                <CheckoutBlock :step="1" @activate="setActiveStep" ref="checkoutBlock1">
                    <template #header>{{ 'Покупець' }}</template>
                    <template #success>
                        <CheckoutContactSuccess />
                    </template>
<!--                    <CheckoutLogIn />-->
                    <CheckoutContact />
                </CheckoutBlock>
                <CheckoutBlock :step="2" @activate="setActiveStep" ref="checkoutBlock2">
                    <template #header>{{ 'Спосіб доставки' }}</template>
                    <template #success>
                        <CheckoutDeliverySuccess />
                    </template>
                    <CheckoutDelivery />
                </CheckoutBlock>
                <CheckoutBlock :step="3" @activate="setActiveStep" ref="checkoutBlock3">
                    <template #header>{{ 'Спосіб оплати' }}</template>
                    <template #success>
                        <CheckoutPaySystemSuccess />
                    </template>
                    <CheckoutPaySystem />

                </CheckoutBlock>
<!--                <CheckoutBlock v-if="!isDeliveryInternational">-->
<!--                    <template #header>{{ recipientTitle }}</template>-->
<!--                    <CheckoutRecipient />-->
<!--                </CheckoutBlock>-->
                <CheckoutBlock :step="4" @activate="setActiveStep" ref="checkoutBlock4">
                    <template #header>{{ 'Товари в замовленні' }}</template>
                    <CheckoutBasket />
                </CheckoutBlock>
                <CheckoutCheckboxes />
            </main>
            <aside class="checkout__aside">
                <div class="checkout-sticky">
                    <CheckoutTotal />
                    <DefButtonPrimary v-if="screenWidth >= 992"
                                      @click="handlerConfirmOrder"
                                      :type="'submit'"
                    >
                        {{ 'Оформити замовлення' }}
                    </DefButtonPrimary>

                </div>
            </aside>
            <CheckoutConfirmButton @click="handlerConfirmOrder"/>
        </form>
    </div>
</template>


<script>
import Analytics from "../../utils/Analytics";
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
    name: 'Checkout',
    computed: {
        ...mapState({
            activeStep: ({checkout}) => checkout.data.activeStep,
            deliveryProps: ({ checkout }) => checkout.data.vue?.delivery?.props,
            dataLayer: ({ checkout }) => checkout.data.vue?.dataLayer,
            // messages: ({ checkout }) => checkout.data.vue.messages,
            // contactTitle: ({ checkout }) => checkout.data.vue?.userFields?.title,
            // deliveryTitle: ({ checkout }) => checkout.data.vue?.delivery?.title,
            // delivery: ({ checkout }) => checkout.data.vue?.delivery,
            // recipientTitle: ({ checkout }) => checkout.data.vue?.recipient?.title,
            // paySystemTitle: ({ checkout }) => checkout.data.vue?.paySystem?.title,
            // coupons: ({ basket }) => basket.data?.price?.coupons,
        }),

        processPersonalDataPropName() {
            return Object.values(this.deliveryProps)?.find(el => el.CODE === 'PROCESS_PERSONAL_DATA')?.inputName;
        },
    },
    methods: {
        ...mapActions({
            saveOrder: 'checkout/saveOrder',
        }),
        ...mapMutations({
            setDataByType: 'checkout/setDataByType',
        }),
        scrollInToBlock(length) {
            const scrollToElement = window.document.querySelector('.checkout-block__active');
            if (scrollToElement) {
                App.helpers.scrollPageTo(window.scrollY + scrollToElement.getBoundingClientRect().top - length);
            }
        },
        handlerConfirmOrder() {
            const invalidInput = document.querySelector('input:invalid');
            if (invalidInput && !this.isInputInIgnoreList(invalidInput)) {
                App.helpers.scrollPageTo(window.scrollY + invalidInput.getBoundingClientRect().top - 100);
            } else {
                const checkoutBottom = document.querySelector('.checkout-confirm-button');
                App.helpers.scrollPageTo(window.scrollY + checkoutBottom.getBoundingClientRect().bottom - 900);
            }
        },
        /**
         * list of inputs for which scroll shouldn't be use
         * @todo list would be added in checkout.js (vuex)
         * */
        isInputInIgnoreList(input) {
            return [this.processPersonalDataPropName].includes(input.getAttribute('name'));
        },
        handlerSaveOrder() {
            this.saveOrder();
        },
        setActiveStep(step) {
            // console.log(step)
            /**
             * scrolling when move back
             */
            if (this.activeStep - step === 1) {
                this.scrollInToBlock(270);
            }
            if ((step - this.activeStep) > 1) return;
            if (step > this.activeStep && !this.validateForm()) return;

            /**
             * scrolling when move forward
             */
            if (step - this.activeStep === 1) {
                this.scrollInToBlock(-100);
            }
            // this.scrollInToBlock(step);
            // this.validateForm();

            this.setDataByType({ type: 'activeStep', data: step })
        },
        validateForm() {
            const requiredStep = this.$refs.checkoutForm.querySelector('.checkout-block__active');
            const inputRequired = requiredStep.querySelectorAll('input[required]');

            let isValid = true;
            let errors = [];

            // console.log(requiredStep);
            inputRequired.forEach(el => {
                if (!el.checkValidity()) {
                    isValid = false;
                    // errors.push(el.getAttribute('errorText'));
                }
            });
            if (errors.length > 0) {

                // this.setDataByType({ type: 'checkoutBlockResult', data: { [this.activeStep] : { state: 'error', data: errors } } })
            }
            else {

                // this.setDataByType({ type: 'checkoutBlockResult', data: { [step] : { state: 'success', data: el } } })
            }

            // return 1;
            return isValid;
        },
    },
    mounted() {
        Analytics.viewCheckout({...this.dataLayer?.beginCheckout});
    }
}
</script>

<style lang="scss" scoped>
    .checkout{
        font-family: Montserrat,Arial,sans-serif;
        counter-reset: section;
        margin-bottom: 64px;
        @include point-lg{
            margin-bottom: 80px;
        }
        &__grid{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 40px;
            @include point-lg{
                grid-template-columns: 1fr 280px;
            }
            //@include point-xl{
                /*grid-template-columns: 1fr 350px;*/
            /*}*/
        }
        &__main{
            display: grid;
            grid-row-gap: 20px;
        }

        &__aside {
            display: grid;
            grid-row-end: none;

            @include point-lg {
                display: block;
                grid-row-end: unset;
            }
        }
        :deep(.checkout-confirm-button) {
            margin-top: -30px; //@todo поправить
        }
        :deep(.checkout-confirm-button .def-button-primary) {
            border-radius: 26px;
            max-width: 230px;
            font-size: 11px;
        }
        :deep(.def-radio,
        .def-checkbox){
            font-weight: 500;
        }
        :deep(.def-radio__text,
        .def-checkbox__text){
            margin-left: 16px;
        }
    }
    .checkout-sticky{
        display: grid;
        grid-row-gap: 8px;
        @include point-lg{
            position: sticky;
            top: 20px;
        }
        :deep(.def-button-primary){
            position: relative;
            top: -10px;
            height: 50px;
        }
    }

</style>