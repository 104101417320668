<template>
    <span class="def-price">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'DefPrice',
    selector: '.def-price',
}
</script>

<style lang="scss" scoped>
.def-price{
    font-size: rem(11);
    line-height: 110%;
}
</style>