const Vue = require('./Vue').default;
const Store = require('./Store').default;
// const Calendar = require('./Calendar').default;
const Helpers = require('./Helpers').default;

class App{
	constructor(vue, store, helpers) {
		this.vue = vue;
		this.store = store;
		// this.Calendar = Calendar;
		this.helpers = helpers;

		this.main();
	}

	empty(mixedValue){
		switch (typeof mixedValue) {
			case 'undefined':
				return true;
			case 'object':
				for(let val in mixedValue)return false;
				return true;
			case 'string':
				return (mixedValue.length==0?true:false);
			case 'boolean':
				return false;
			case 'number':
				return isNaN(mixedValue);
			case 'function':
				return false;
			default:
				alert('O_o');
		}
	}

	/**
	 * I can't change name for this functions because of
	 * back end and their foundation core module
	 */

	setStores(results) {
		this.store.setResultsStores(results);
	}

	setStoreModules(modules) {
		this.store.setStores(modules);
	}

	/**
	 * handlers
	 */
	handlerWindowResize() {
		window.addEventListener('resize', () => {
			this.store.commit('global/changeScreenWidth');
		});
	}

	/**
	 * Main function (start App function);
	 */

	main() {
		// this.handlerWindowResize();
	}

}

export default new App(
	new Vue(),
	new Store(),
	new Helpers(),
);