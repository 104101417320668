import { store } from "../store";

class Store{
	/**
	 * set results
	 * @param name
	 * @param value
	 */
	setResultsStore(name, value) {
		store.commit('store/setResultsStore', {
			'name': name,
			'value': value,
		});
	}
	setResultsStores(values) {
		if (typeof values == 'string') {
			values = JSON.parse(values);
		}
		if (typeof values == 'object') {
			for (let key in values) {
				this.setResultsStore(key, values[key]);
			}
		}
	}

	/**
	 * set module stores
	 * @param values
	 */
	setStores(values) {
		if (typeof values == 'string') {
			values = JSON.parse(values);
		}

		if (typeof values == 'object') {
			for (let key in values) {
				this.setStore(key, values[key]);
			}
		}
	}
	setStore(name, value) {
		store.commit('store/setStore', { name, value })
	}

	setDefaultStores() {
		if (process.env.NODE_ENV === 'development') {
			const data = require('../store/defaultData').default;

			for (let name in data) {
				const value = data [ name ];

				if (name !== 'results') {
					store.commit('store/setStore', { name, value});
				} else {
					this.setResultsStores(value);
				}
			}
		}
	}

	/** commit **/
	commit(name, payload) {
		store.commit(name, payload);
	}

	/**
	 * getters
	 */
	getStoreContext() {
		return store;
	}
}

export default Store;