<template>
    <label class="def-checkbox">
        <input
            :name="name"
            :required="required"
            :value="value"
            :checked="checked"
            :disabled="disabled"
            type="checkbox"
            class="def-checkbox__input"
        >
        <span class="def-checkbox__front">
            <svg class="def-checkbox__icon"><use xlink:href="#svg-check"></use></svg>
        </span>
        <component :is="tag" v-if="$slots.default" class="def-checkbox__text">
            <slot />
        </component>
    </label>
</template>

<script>
export default {
    name: 'DefCheckbox',
    selector: '.def-checkbox',
    props: {
        'name': String,
        'required': Boolean,
        'value': [Boolean, Number, String],
        'checked': Boolean,
        'disabled': Boolean,
        'tag': {
            type: String,
            default: 'span',
        }
    },
    mounted() {
        // console.log(this.$slots?.default()[0].type);
    }
}
</script>

<style lang="scss" scoped>
.def-checkbox{
    display: inline-flex;
    cursor: pointer;
    &__input{
        display: none;
        &:checked + .def-checkbox__front{
            background-color: var(--background-black);
            border-color: var(--background-black);
            .def-checkbox__icon{
                fill: #fff;
            }
        }
        &:disabled + .def-checkbox__front,
        &:disabled + .def-checkbox__front + .def-checkbox__text{
            cursor: context-menu;
        }
    }
    &__front{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--background-grey);
        width: 18px;
        height: 18px;
        transition: .2s linear border-bottom-color, .2s linear background-color;
    }
    &__icon{
        display: block;
        height: 18px;
        width: 18px;
        fill: transparent;
        transition: .2s linear fill;
    }
    &__text{
        margin-left: 10px;
    }
}
</style>